// ============================================================================
// COMPONENTS - Onepagenav                                 Modified: 2020-05-28
// ============================================================================
//
// <div class="onepagebar-container">
//   <div  class="onepagebar">
//
//     <!-- Brand -->
//     <div class="onepagebar-brand">
//       <a href="{{link_url::2}}" title="Contao Open Source CMS">
//         <img src="files/theme/dist/img/contao-logo.svg" alt="Logo Contao Open Source CMS" />
//         Contao
//       </a>
//     </div>
//
//     <!-- Toggle -->
//     <div class="onepagebar-toggler">
//       <span class="menu-caption"></span>
//       <span class="menu-icon">
//         <span class="menu-icon__inner"></span>
//       </span>
//     </div>
//
//     <!-- Navigation -->
//     <div id="onepagebar-nav" class="onepagebar-overlay">
//       <div class="onepagebar-overlay-content">
//         <div class="onepagebar-nav-group">
//           {{insert_module::6}}
//           {{insert_module::2}}
//         </div>
//       </div>
//     </div>
//
//     <!-- Text -->
//     <span class="onepagebar-text">
//       Navbar text with an inline element
//     </span>
//
//   </div>
// </div>
//

$onepagebar-base-size:                      16px !default;
$onepagebar-breakpoint:                     $grid-breakpoint--lg !default;
$onepagebar-overlay-background:             rgba($color-black,.9) !default;

$onepagebar-height:                         ($onepagebar-base-size * 3.5) !default;
$onepagebar-height--small:                  $onepagebar-height !default;
$onepagebar-horizontal-alignment:           flex-start !default;      // (flex-start), center, flex-end, stretch
$onepagebar-vertical-alignment:             center !default;

$onepagebar-brand-margin-right:             $base-spacing-unit !default;
$onepagebar-brand-image-height:             ($onepagebar-base-size * 2) !default;
$onepagebar-brand-image-padding-top:        0rem !default;
$onepagebar-brand-image-padding-bottom:     0rem !default;
$onepagebar-brand-image-margin-right:       ($base-spacing-unit * 0.5) !default;
$onepagebar-brand-text-color:               $color-brand !default;
$onepagebar-brand-text-font-size:           ($base-font-size * 1.5) !default;
$onepagebar-brand-text-font-weight:         $base-weight-bold !default;
$onepagebar-brand-text-vertical-alignment:  center !default;          // (center), flex-start, flex-end

$onepagebar-nav-horizontal-alignment:       flex-end !default;        // flex-start, (flex-end), center
$onepagebar-nav-vertical-alignment:         center !default;          // (center), flex-start, flex-end, stretch
$onepagebar-nav-padding-top:                0 !default;
$onepagebar-nav-padding-bottom:             0 !default;
$onepagebar-nav-color:                      $page-header-text-color !default;
$onepagebar-nav-color--hover:               $color-brand !default;
$onepagebar-nav-color--active:              $color-brand !default;
$onepagebar-nav-decoration-color--hover:    $color-brand !default;
$onepagebar-nav-decoration-color--active:   $color-brand !default;

$onepagebar-toggler-color:                  $page-header-text-color !default;
$onepagebar-toggler-color--hover:           $page-header-text-color !default;
$onepagebar-toggler-color--active:          $page-header-text-color !default;
$onepagebar-toggler-font-size:              ($base-font-size * 1.25) !default;
$onepagebar-toggler-font-weight:            $base-weight-normal !default;

$onepagebar-text-color:                     $page-header-text-color !default;
$onepagebar-text-font-size:                 ($base-font-size * 1.25) !default;
$onepagebar-text-font-weight:               $base-weight-normal !default;

.onepagebar-container {
  @extend %clearfix;
  width: 100%;
  padding-right: $grid-gutter--half;
  padding-left: $grid-gutter--half;
  margin-right: auto;
  margin-left: auto;
  @include media-breakpoint-up-lg{
    max-width: $container-max-width--lg;
  }
  @include media-breakpoint-up-xl{
    max-width: $container-max-width--xl;
  }
  @include media-breakpoint-up-hd{
  max-width: $container-max-width--hd;
  }
}

.onepagebar {
  height: $onepagebar-height;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  justify-content: $onepagebar-horizontal-alignment;
  align-items: $onepagebar-vertical-alignment;
  padding: 0;

  &-brand {
    margin-right: $onepagebar-brand-margin-right;

    img {
      float: left;
      height: $onepagebar-brand-image-height;
      margin-right: $onepagebar-brand-image-margin-right;
      padding-top: $onepagebar-brand-image-padding-top;
      padding-bottom: $onepagebar-brand-image-padding-bottom;
    }

    a {
      color: $onepagebar-brand-text-color;
      font-size: $onepagebar-brand-text-font-size;
      font-weight: $onepagebar-brand-text-font-weight;
      line-height: 1;
      display: flex;
      align-items: $onepagebar-brand-text-vertical-alignment;
    }
  }

  &-text {
    flex-grow: 1;
    color: $onepagebar-text-color;
    font-size: $onepagebar-text-font-size;
    font-weight: $onepagebar-text-font-weight;
    text-align: right;
  }

}

@media screen and (max-width: #{$onepagebar-breakpoint - 0.0625em}) {

  .onepagebar {
    justify-content: space-between;

    &-toggler {
      height: $onepagebar-height;
      display: flex;
      align-items: center;
      color: $onepagebar-toggler-color;
      font-size: $onepagebar-toggler-font-size;
      font-weight: $onepagebar-toggler-font-weight;
      cursor: pointer;

      &:hover {
        color: $onepagebar-toggler-color--hover;
      }

      .menu-icon {
        position: relative;
        width: 1.5rem;
        margin-left: 0.5rem;
        display: inline-block;
        cursor: pointer;

        &__inner {
          width: 100%;
          background-color: currentcolor;
          position: absolute;
          left: 0;
          top: 50%;
          margin-top: -1.5px;
          height: 3px;
          transition: transform 0.15s ease;

          &::after,
          &::before {
            background-color: currentcolor;
            position: absolute;
            display: block;
            content: '';
            width: 100%;
            right: 0;
            height: 3px;
            transition: transform 0.15s ease;
          }

          &::before {
            top: -7px
          }

          &::after {
            bottom: -7px
          }
        }
      }

      &--active .menu-icon {
        &:hover {
          color: $onepagebar-toggler-color--active;
          cursor: pointer;
        }

        &__inner {
          transform: rotate(45deg);

          &::before {
            opacity: 0;
          }

          &::after {
            transform: rotate(-90deg);
            bottom: 0;
            width: 100%;
          }
        }
      }
    }
  }

  .onepagebar-overlay {
    height: 0%;
    width: 100%;
    position: fixed;
    z-index: 1020;
    top: $onepagebar-height--small;
    left: 0;
    overflow-y: hidden;
    transition: 0.5s;

    &-content {
      position: relative;
      top: 0;
      width: 100%;
      text-align: center;
      margin-top: 0px;
      background-color: $onepagebar-overlay-background;
      height: 100%;

      .onepagebar-nav-group {
        height: calc(100% - #{ $onepagebar-height--small });
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        > .mod_onepage_navigation {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;

          ul {

            li {
              @media screen and (max-height: 479px) {
                padding: .5rem 0;
                font-size: ($base-font-size * 1.125);
              }

              @media screen and (min-height: 480px) {
                padding: 1rem 0;
                font-size: ($base-font-size * 1.25);
              }

              a {
                padding: 0 .25rem;
                color: $onepagebar-nav-color;

                &:hover {
                  color: $onepagebar-nav-color--hover;
                  border-bottom: 2px solid $onepagebar-nav-decoration-color--hover;
                }
              }

              &.active a {
                color: $onepagebar-nav-color--active;
                border-bottom: 2px solid $onepagebar-nav-decoration-color--active;
                cursor: pointer;
              }
            }
          }
        }

        > .mod_customnav {
          @media screen and (max-height: 479px) {
            display: none;
          }

          @media screen and (min-height: 480px) {
            display: flex;
            justify-content: center;

            ul {

              li {
                padding: 1.5rem 0;
                font-size: ($base-font-size);

                a {
                  padding: 0 .75rem;
                  color: $onepagebar-nav-color;

                  &:hover {
                  cursor: pointer;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: #{$onepagebar-breakpoint}) {

  #onepagebar-nav {
    flex-grow: 1;
    display: flex;
    height: $onepagebar-height;
    justify-content: $onepagebar-nav-horizontal-alignment;
    align-items: $onepagebar-nav-vertical-alignment;
    padding-top: $onepagebar-nav-padding-top;
    padding-bottom: $onepagebar-nav-padding-bottom;

    ul {
      display: flex;

      li {

        a {
          margin: 0 0.5rem;
          padding: 0 .25rem .5rem .25rem;
          color: $onepagebar-nav-color;

          &:hover {
            color: $onepagebar-nav-color--hover;
            border-bottom: 2px solid $onepagebar-nav-decoration-color--hover;
          }
        }

        &.active a {
          color: $onepagebar-nav-color--active;
          border-bottom: 2px solid $onepagebar-nav-decoration-color--active;
          cursor: pointer;
        }
      }
    }

    .mod_customnav {
      display: none;
    }
  }
}
