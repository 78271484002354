// ============================================================================
// COMPONENTS - Gallery                                    Modified: 2020-05-28
// benoetigt: lightGallery
// ============================================================================
//
// 1. Responsive Image Gallery (Thumbnails)
// 2. lightGallery


// 1. Responsive Image Gallery (Thumbnails)
// ============================================================================

$rig-overlay-direction:         "center" !default;   // top, bottom, right, left, center
$rig-overlay-background:        rgba(0, 0, 0, 0.5) !default;
$rig-cell-border:               $base-spacing-unit--xs solid $color-white !default;
$rig-icon-show:                 true !default;
$rig-icon-color:                $color-white !default;
$rig-icon-font-size:            2rem !default;
$rig-text-show:                 true !default;
$rig-text-color:                $color-white !default;
$rig-text-font-size:            1rem !default;


.ce_gallery {
  @extend %clearfix;
  @include navigation;
  border-top: $rig-cell-border;
  border-left: $rig-cell-border;

  li {
    float: left;
  }
}

@include media-breakpoint-down-md {
  .rig-columns_3 li, .rig-columns_4 li, .rig-columns_6 li, .rig-columns_8 li {
    width: 50%;
  }
}

@include media-breakpoint-up-md {
  .rig-columns_3 li, .rig-columns_6 li {
    width: 33.33333%;
  }

  .rig-columns_4 li, .rig-columns_8 li {
    width: 25%;
  }
}

@include media-breakpoint-up-lg {
  .rig-columns_6 li {
    width: 16.66667%;
  }
}

@include media-breakpoint-up-xl {
  .rig-columns_8 li {
    width: 12.5%;
  }
}

.rig-img {
  display: block;
  width: 100%;
}

.rig-cell {
  position: relative;
  border-right: $rig-cell-border;
  border-bottom: $rig-cell-border;
}

.rig-overlay {
  @if $rig-overlay-direction == "center" {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
  } @else if $rig-overlay-direction == "top" {
    bottom: 100%;
    left: 0;
    right: 0;
    width: 100%;
    height: 0;
  } @else if $rig-overlay-direction == "bottom" {
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 0;
  } @else if $rig-overlay-direction == "right" {
    bottom: 0;
    left: 100%;
    right: 0;
    width: 0;
    height: 100%;
  } @else if $rig-overlay-direction == "left" {
    bottom: 0;
    left: 0;
    right: 0;
    width: 0;
    height: 100%;
  }
  position: absolute;
  cursor: pointer;
  background: $rig-overlay-background;
  overflow: hidden;
  transition: .5s ease;
}

.rig-cell:hover .rig-overlay {
  @if $rig-overlay-direction == "center" {
    opacity: 1;
  } @else if $rig-overlay-direction == "top" {
    bottom: 0;
    height: 100%;
  } @else if $rig-overlay-direction == "bottom" {
    height: 100%;
  } @else if $rig-overlay-direction == "right" {
    width: 100%;
    left: 0;
  } @else if $rig-overlay-direction == "left" {
    width: 100%;
  }
}

.rig-content {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

@if $rig-icon-show {
  .rig-icon:after {
    font-family: $base-font-icon-family;
    content: $icon-search;
    font-size: $rig-icon-font-size;
    color: $rig-icon-color
  }

  .rig-icon-flip-horizontal {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
    -webkit-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1);
  }
} @else {
  .rig-icon {
    display: none;
  }
}

@if $rig-text-show {
  .rig-text {
    font-size: $rig-text-font-size;
    color: $rig-text-color;
  }
} @else {
  .rig-text {
    display: none;
  }
}


// 2. lightGallery
// ============================================================================

$lg-background:                         $color-gray--700 !default;
$lg-background-blur:                    true !default;
$lg-toolbar-background:                 $color-gray--800 !default;
$lg-icon-color:                         $color-white !default;
$lg-icon-color--hover:                  $color-highlight !default;
$lg-progressbar-background:             $color-white !default;
$lg-progressbar-background--progress:   $color-highlight !default;
$lg-actions-button-color:               $color-white !default;
$lg-actions-button-color--hover:        $color-highlight !default;
$lg-actions-button-background:          $color-gray--800 !default;
$lg-actions-button-doublesize:          true !default;
$lg-thumb-item-border-color:            $color-gray--600 !default;
$lg-thumb-item-border-color--hover:     $color-highlight !default;
$lg-sub-html-color:                     $color-white !default;
$lg-sub-html-background:                rgba($color-black, 0.5) !default;
$lg-sub-html-textalign:                 center !default;

.lg-toolbar, .lg-thumb-outer {
  background-color: $lg-toolbar-background;
}

.lg-toolbar .lg-icon {
  color: $lg-icon-color;
  width: 40px;
  @include media-breakpoint-up-md {
    width: 50px;
  }

  &:hover {
    color: $lg-icon-color--hover;
  }
}

#lg-counter {
  color: $lg-icon-color;
}

.lg-actions .lg-next, .lg-actions .lg-prev {
  background-color: $lg-actions-button-background;
  color: $lg-actions-button-color;
  @if $lg-actions-button-doublesize {
    font-size: 42px;
    margin-top: -40px;
    padding: 18px 10px 19px;
  } @else {
    font-size: 22px;
    margin-top: -10px;
    padding: 8px 10px 9px;
  }
  @include border-radius($base-border-radius);
  @include box-shadow($color-box-shadow);

  &:hover {
    color: $lg-actions-button-color--hover;
  }
}

.lg-progress-bar {
  background-color: $lg-progressbar-background;

  .lg-progress {
    background-color: $lg-progressbar-background--progress;
  }
}

.lg-backdrop {
  @if $lg-background-blur {
    @media screen and (min-width: 80.063em) {
      // background-color: #FFFF66;
      /* Full height */
      width: 105%;
      height: auto;
      margin: -20px;
      /* Center and scale the image nicely */
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      background-color: rgba(0, 0, 0, 0.75);
      opacity: 1;
      /* Add the blur effect */
      filter: blur(15px);
    }
  }
}
.lg-outer {
  @if $lg-background-blur {
    background-color: $lg-background;
    @media screen and (min-width: 80.063em) {
      background-color: rgba(0, 0, 0, 0.75);
    }
  } @else {
    background-color: $lg-background;
  }

  .lg-thumb-item {
    border: 2px solid $lg-thumb-item-border-color;
    @include border-radius($base-border-radius);

    &.active, &:hover {
      border-color: $lg-thumb-item-border-color--hover;
    }
  }

  .lg-toogle-thumb {
    color: $lg-icon-color;
    background-color: $lg-toolbar-background;
    @include border-top-radius($base-border-radius);

    &:hover {
      color: $lg-icon-color--hover;
    }
  }
}

.lg-sub-html {
  color: $lg-sub-html-color;
  background-color: $lg-sub-html-background;
  text-align: $lg-sub-html-textalign;
}