@charset "UTF-8";
/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url("../fonts/roboto-v20-latin-regular.woff2") format("woff2"), url("../fonts/roboto-v20-latin-regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local("Roboto Bold"), local("Roboto-Bold"), url("../fonts/roboto-v20-latin-700.woff2") format("woff2"), url("../fonts/roboto-v20-latin-700.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/*
@mixin screen-mobile-portrait($max, $orientation: false) {
  @include mq($max-width: $max, $orientation: $orientation) {
    @content;
  }
}


// screen
@mixin screen($min, $max, $orientation: false) {
  @include mq($min-width: $min, $max-width: $max, $orientation: $orientation) {
    @content;
  }
}

@mixin max-screen($max) {
  @include mq($max-width: $max) {
    @content;
  }
}

@mixin min-screen($min) {
  @include mq($min-width: $min) {
    @content;
  }
}

@mixin screen-height($min, $max, $orientation: false) {
  @include mq($min-height: $min, $max-height: $max, $orientation: $orientation) {
    @content;
  }
}

@mixin max-screen-height($max) {
  @include mq($max-height: $max) {
    @content;
  }
}

@mixin min-screen-height($min) {
  @include mq($min-height: $min) {
    @content;
  }
}


// orientation
@mixin landscape() {
  @include mq($orientation: landscape) {
    @content;
  }
}

@mixin portrait() {
  @include mq($orientation: portrait) {
    @content;
  }
}
*/
html, body, div, span, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, input, label, select, button, textarea, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  outline: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

ol,
ul {
  list-style: none;
}

a {
  outline: 0;
  text-decoration: none;
}

.inside {
  position: relative;
}

.float_left {
  float: left;
}

.float_right {
  float: right;
}

.clear {
  height: 0.1px;
  font-size: 0.1px;
  line-height: 0.1px;
  clear: both;
}

.clearfix::after,
.container::after,
.container-fluid::after,
.row::after,
nav::after,
.nav::after,
.sidr-class-nav::after,
.onepagebar-nav::after,
.nav-horizontal ul::after,
.onepagebar-container::after,
.ce_gallery::after {
  content: "";
  display: table;
  clear: both;
}

.invisible,
.sidr-class-invisible {
  width: 0;
  height: 0;
  left: -1000px;
  top: -1000px;
  position: absolute;
  overflow: hidden;
  display: inline;
}

@font-face {
  font-family: 'font-icon';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/font-icon-small.woff") format("woff"), url("../fonts/font-icon-small.woff2") format("woff2");
}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it.
     Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: 'font-icon';
    src: url("../fonts/font-icon-small.svg") format("svg");
  }
}

[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: 'font-icon' !important;
  speak: none;
  font-size: 1em;
  font-variant: normal;
  text-transform: none;
  line-height: 1.5;
  text-align: center;
}

@media (max-width: 35.9375em) {
  [class^="icon-"]:before, [class*=" icon-"]:before {
    font-size: 0.9375em;
    line-height: 1.4375;
  }
}

@media (min-width: 90em) {
  [class^="icon-"]:before, [class*=" icon-"]:before {
    font-size: 1.125em;
    line-height: 1.5625;
  }
}

.icon-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  -webkit-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.icon-arrow-up:before {
  content: "\f062";
}

html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*, *:before, *:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

html {
  font-family: "Roboto", sans-serif, sans-serif;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.5;
  background-color: #fff;
  color: #343a40;
  overflow-y: scroll;
  min-height: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

@media (max-width: 35.9375em) {
  html {
    font-size: 0.9375em;
    line-height: 1.4375;
  }
}

@media (min-width: 90em) {
  html {
    font-size: 1.125em;
    line-height: 1.5625;
  }
}

[id="overlay"] {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 1010;
  display: none;
}

[id="wrapper"] {
  min-width: 340px;
  margin: 0 auto;
  padding: 0;
}

[id="header"] {
  color: #333333;
  background-color: #ffffff;
  /*
  display: block;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 1000;
  */
}

[id="header-infobar"] {
  color: #fff;
  background-color: #343a40;
}

[id="header-navbar"] {
  color: #fff;
  background-color: #343a40;
}

[id="footer"] {
  color: #fff;
  background-color: #333333;
}

[id="footer-infobar"] {
  color: #fff;
  background-color: #343a40;
}

[id="footer-navbar"] {
  color: #fff;
  background-color: #041f7e;
}

.mod_article {
  padding-top: 2rem;
  padding-bottom: 2rem;
  position: relative;
}

.article-full-width {
  padding-top: 0;
  padding-bottom: 0;
}

.article-full-width p:last-child {
  margin-bottom: 0;
}

.container {
  width: 100%;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 48em) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 62em) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 75em) {
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 90em) {
  .container {
    max-width: 1400px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -0.9375rem;
  margin-left: -0.9375rem;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col,
.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12,
.col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12,
.col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12,
.col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12,
.col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12 {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  width: 100%;
  box-sizing: border-box;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  line-height: 1.25;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h1, .h1 {
  font-size: 2.25rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #041f7e;
  font-weight: 700;
}

h2, .h2 {
  font-size: 1.75rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #041f7e;
  font-weight: 700;
}

h3, .h3 {
  font-size: 1.5rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #333333;
  font-weight: 700;
}

h4, .h4 {
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #343a40;
  font-weight: 700;
}

h5, .h5 {
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #343a40;
  font-weight: 400;
}

h6, .h6 {
  font-size: 0.875rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #343a40;
  font-weight: 400;
}

h1 + h2,
h1 + h3,
h1 + h4,
h1 + .ce_text {
  margin-top: 1.375rem;
}

h2 + h3,
h2 + h4,
h2 + .ce_text {
  margin-top: .875rem;
}

h3 + h4,
h3 + .ce_text {
  margin-top: .75rem;
}

ul, ol, dl,
blockquote, p, address,
hr,
fieldset, figure,
pre {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p + h2,
ul + h2,
ol + h2 {
  margin-top: 1.625rem;
}

p + h3,
ul + h3,
ol + h3 {
  margin-top: 1.5rem;
}

p + h4,
ul + h4,
ol + h4 {
  margin-top: 1.125rem;
}

ul, ol, dd {
  margin-left: 1rem;
  list-style: disc;
}

code, kbd, pre, samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  color: #343a40;
  background: #e9ecef;
  overflow-x: scroll;
  padding: 1rem;
}

i {
  font-style: normal;
}

figure {
  margin: 0;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}

.tab-adress em {
  display: inline-block;
  font-style: normal;
  width: 4.25rem;
}

/*
button,textarea,input, select, a {
  -webkit-tab-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tab-highlight-color: transparent;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
*/
a[href^="http"]:after {
  font-family: "font-icon", sans-serif;
  content: "";
  padding-left: 4px;
}

[id="main"] a,
.mod_cms_accept_tags a,
.mod_cms_cookie_bar a {
  color: #00a2e8;
  border-bottom: 1px dotted #00a2e8;
}

[id="main"] a:not(.btn):hover,
.mod_cms_accept_tags a:not(.btn):hover,
.mod_cms_cookie_bar a:not(.btn):hover {
  border-bottom: 1px solid #00a2e8;
}

.toplink {
  background-color: #041f7e;
  border: 2px solid #ffffff;
  border-radius: 4px;
  display: table;
  width: 3rem;
  height: 3rem;
  bottom: 1.25rem;
  right: 1.25rem;
  position: fixed;
  z-index: 5;
}

.toplink:hover {
  background-color: #02134d;
  border-color: #e6e6e6;
}

.toplink a {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.toplink a::after {
  font-family: "font-icon", sans-serif;
  font-size: 1.75rem;
  line-height: 1;
  content: "";
  color: #fff;
}

.toplink--left {
  left: 1.25rem;
}

.toplink--circle {
  border-radius: 50%;
}

a.btn,
span.btn,
button.btn, input.submit,
button.submit {
  display: inline-block;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: #666666;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  background-image: none;
  white-space: nowrap;
  padding: 0.375rem 0.75rem;
  border: 2px solid transparent;
  border-radius: 4px;
}

a.btn,
span.btn,
button.btn {
  margin-bottom: 1rem;
}

a.btn:focus, a.btn.focus, a.btn:hover, a.btn.hover,
span.btn:focus,
span.btn.focus,
span.btn:hover,
span.btn.hover,
button.btn:focus,
button.btn.focus,
button.btn:hover,
button.btn.hover {
  outline: 0;
}

a.btn:hover,
span.btn:hover,
button.btn:hover {
  cursor: pointer;
}

a.btn--primary,
span.btn--primary,
button.btn--primary,
input.submit,
button.submit {
  color: #fff;
  background-color: #041f7e;
  border-color: transparent;
}

a.btn--primary:hover,
span.btn--primary:hover,
button.btn--primary:hover,
input.submit:hover,
button.submit:hover {
  background-color: #02134d;
  border-color: transparent;
}

a.btn--secondary,
span.btn--secondary,
button.btn--secondary {
  color: #fff;
  background-color: #7e6304;
  border-color: transparent;
}

a.btn--secondary:hover,
span.btn--secondary:hover,
button.btn--secondary:hover {
  background-color: #4d3c02;
  border-color: transparent;
}

a.btn-outline--primary,
span.btn-outline--primary,
button.btn-outline--primary {
  color: #041f7e;
  border-color: #041f7e;
}

a.btn-outline--primary:hover,
span.btn-outline--primary:hover,
button.btn-outline--primary:hover {
  color: #02134d;
  border-color: #02134d;
}

a.btn-outline--secondary,
span.btn-outline--secondary,
button.btn-outline--secondary {
  color: #7e6304;
  border-color: #7e6304;
}

a.btn-outline--secondary:hover,
span.btn-outline--secondary:hover,
button.btn-outline--secondary:hover {
  color: #4d3c02;
  border-color: #4d3c02;
}

a.btn-block,
span.btn-block,
button.btn-block {
  width: 100%;
}

.chk-container {
  display: block;
  position: relative;
  padding-left: 2rem;
  margin-bottom: 0.75rem;
  cursor: pointer;
  user-select: none;
}

.chk-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.chk-container:hover input ~ .chk-checkmark {
  background-color: #b1bbc4;
}

.chk-container input:checked ~ .chk-checkmark {
  background-color: #041f7e;
}

.chk-container:hover input:checked ~ .chk-checkmark {
  background-color: #02134d;
}

.chk-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: #ced4da;
  border-radius: 4px;
}

.chk-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.chk-container input:checked ~ .chk-checkmark:after {
  display: block;
}

.chk-container .chk-checkmark:after {
  left: 9px;
  top: 3px;
  width: 7px;
  height: 15px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

/*
/* The container
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background
.container input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* When the radio button is checked, add a blue background
.container:hover input:checked ~ .checkmark {
  background-color: #33FF33;
}

/* Create the indicator (the dot/circle - hidden when not checked)
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle)
.container .checkmark:after {
 	top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
*/
.chk-switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
}

.chk-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.chk-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ced4da;
  transition: .4s;
}

.chk-slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: #fff;
  transition: .4s;
}

input:checked + .chk-slider {
  background-color: #041f7e;
}

input:hover:checked + .chk-slider {
  background-color: #02134d;
}

input:hover:not(:checked) + .chk-slider {
  background-color: #b1bbc4;
}

input:checked + .chk-slider:before {
  transform: translateX(16px);
}

.chk-slider {
  border-radius: 24px;
}

.chk-slider:before {
  border-radius: 50%;
}

nav ul,
.nav ul,
.sidr-class-nav ul,
.onepagebar-nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

nav a,
.nav a,
.sidr-class-nav a,
.onepagebar-nav a {
  text-decoration: none;
}

.nav-right {
  float: right;
}

.nav-vertical a,
.nav-vertical span.active,
.nav-vertical strong.active {
  display: block;
  font-weight: normal;
  padding: 0.25rem 0;
}

.nav-vertical span.active,
.nav-vertical strong.active {
  cursor: default;
}

.nav-horizontal {
  display: inline-block;
}

.nav-horizontal li {
  float: left;
}

.nav-horizontal a,
.nav-horizontal span.active,
.nav-horizontal strong.active {
  font-weight: normal;
  padding: 0.25rem 1rem;
}

.nav-horizontal span.active,
.nav-horizontal strong.active {
  cursor: default;
}

.nav-horizontal a.first,
.nav-horizontal span.first,
.nav-horizontal strong.first {
  padding-left: 0;
}

.nav-horizontal a.last,
.nav-horizontal span.last,
.nav-horizontal strong.last {
  padding-right: 0;
}

/*
.nav-breadcrumb {
  border: $base-border;
  padding: ($nav-main-item-padding / 2) $nav-main-item-padding;
  ul {
    @extend %clearfix;
  }

  li {
    @include media-query(screen-offcanvas) {
      float: left;
      padding-right: $nav-main-item-padding;
    }
  }

  a {
    &:after {
      font-size: 0.75rem;
      display: inline-block;
      content: "?";
      vertical-align: middle;
      padding-left: $$nav-main-item-padding;
    }
  }
}

*/
.onepagebar-container {
  width: 100%;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 62em) {
  .onepagebar-container {
    max-width: 960px;
  }
}

@media (min-width: 75em) {
  .onepagebar-container {
    max-width: 1140px;
  }
}

@media (min-width: 90em) {
  .onepagebar-container {
    max-width: 1400px;
  }
}

.onepagebar {
  height: 96px;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
}

.onepagebar-brand {
  margin-right: 1rem;
}

.onepagebar-brand img {
  float: left;
  height: 32px;
  margin-right: 0.5rem;
  padding-top: 0rem;
  padding-bottom: 0rem;
}

.onepagebar-brand a {
  color: #00a2e8;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  display: flex;
  align-items: center;
}

.onepagebar-text {
  flex-grow: 1;
  color: #333333;
  font-size: 1.25rem;
  font-weight: 400;
  text-align: right;
}

@media screen and (max-width: 61.9375em) {
  .onepagebar {
    justify-content: space-between;
  }
  .onepagebar-toggler {
    height: 96px;
    display: flex;
    align-items: center;
    color: #333333;
    font-size: 1.25rem;
    font-weight: 400;
    cursor: pointer;
  }
  .onepagebar-toggler:hover {
    color: #00a2e8;
  }
  .onepagebar-toggler .menu-icon {
    position: relative;
    width: 1.5rem;
    margin-left: 0.5rem;
    display: inline-block;
    cursor: pointer;
  }
  .onepagebar-toggler .menu-icon__inner {
    width: 100%;
    background-color: currentcolor;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -1.5px;
    height: 3px;
    transition: transform 0.15s ease;
  }
  .onepagebar-toggler .menu-icon__inner::after, .onepagebar-toggler .menu-icon__inner::before {
    background-color: currentcolor;
    position: absolute;
    display: block;
    content: '';
    width: 100%;
    right: 0;
    height: 3px;
    transition: transform 0.15s ease;
  }
  .onepagebar-toggler .menu-icon__inner::before {
    top: -7px;
  }
  .onepagebar-toggler .menu-icon__inner::after {
    bottom: -7px;
  }
  .onepagebar-toggler--active .menu-icon:hover {
    color: #333333;
    cursor: pointer;
  }
  .onepagebar-toggler--active .menu-icon__inner {
    transform: rotate(45deg);
  }
  .onepagebar-toggler--active .menu-icon__inner::before {
    opacity: 0;
  }
  .onepagebar-toggler--active .menu-icon__inner::after {
    transform: rotate(-90deg);
    bottom: 0;
    width: 100%;
  }
  .onepagebar-overlay {
    height: 0%;
    width: 100%;
    position: fixed;
    z-index: 1020;
    top: 56px;
    left: 0;
    overflow-y: hidden;
    transition: 0.5s;
  }
  .onepagebar-overlay-content {
    position: relative;
    top: 0;
    width: 100%;
    text-align: center;
    margin-top: 0px;
    background-color: rgba(255, 255, 255, 0.9);
    height: 100%;
  }
  .onepagebar-overlay-content .onepagebar-nav-group {
    height: calc(100% - 56px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .onepagebar-overlay-content .onepagebar-nav-group > .mod_onepage_navigation {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@media screen and (max-width: 61.9375em) and (max-height: 479px) {
  .onepagebar-overlay-content .onepagebar-nav-group > .mod_onepage_navigation ul li {
    padding: .5rem 0;
    font-size: 1.125rem;
  }
}

@media screen and (max-width: 61.9375em) and (min-height: 480px) {
  .onepagebar-overlay-content .onepagebar-nav-group > .mod_onepage_navigation ul li {
    padding: 1rem 0;
    font-size: 1.25rem;
  }
}

@media screen and (max-width: 61.9375em) {
  .onepagebar-overlay-content .onepagebar-nav-group > .mod_onepage_navigation ul li a {
    padding: 0 .25rem;
    color: #333333;
  }
  .onepagebar-overlay-content .onepagebar-nav-group > .mod_onepage_navigation ul li a:hover {
    color: #00a2e8;
    border-bottom: 2px solid #00a2e8;
  }
  .onepagebar-overlay-content .onepagebar-nav-group > .mod_onepage_navigation ul li.active a {
    color: #00a2e8;
    border-bottom: 2px solid #00a2e8;
    cursor: pointer;
  }
}

@media screen and (max-width: 61.9375em) and (max-height: 479px) {
  .onepagebar-overlay-content .onepagebar-nav-group > .mod_customnav {
    display: none;
  }
}

@media screen and (max-width: 61.9375em) and (min-height: 480px) {
  .onepagebar-overlay-content .onepagebar-nav-group > .mod_customnav {
    display: flex;
    justify-content: center;
  }
  .onepagebar-overlay-content .onepagebar-nav-group > .mod_customnav ul li {
    padding: 1.5rem 0;
    font-size: 1rem;
  }
  .onepagebar-overlay-content .onepagebar-nav-group > .mod_customnav ul li a {
    padding: 0 .75rem;
    color: #333333;
  }
  .onepagebar-overlay-content .onepagebar-nav-group > .mod_customnav ul li a:hover {
    cursor: pointer;
  }
}

@media screen and (min-width: 62em) {
  #onepagebar-nav {
    flex-grow: 1;
    display: flex;
    height: 96px;
    justify-content: flex-end;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
  }
  #onepagebar-nav ul {
    display: flex;
  }
  #onepagebar-nav ul li a {
    margin: 0 0.5rem;
    padding: 0 .25rem .5rem .25rem;
    color: #333333;
  }
  #onepagebar-nav ul li a:hover {
    color: #00a2e8;
    border-bottom: 2px solid #00a2e8;
  }
  #onepagebar-nav ul li.active a {
    color: #00a2e8;
    border-bottom: 2px solid #00a2e8;
    cursor: pointer;
  }
  #onepagebar-nav .mod_customnav {
    display: none;
  }
}

.mod_article.hero {
  max-width: none;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  min-height: 600px;
  position: relative;
  padding: 0;
  z-index: 0;
}

.ce_hero .image_container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.ce_hero img {
  filter: brightness(100%);
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.content_container {
  text-align: center;
  margin: 0 auto;
  min-height: 600px;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.content_teaser {
  color: #333333;
  background-color: rgba(255, 255, 255, 0.6);
  width: 80%;
  padding: 1rem;
}

.content_button {
  margin-top: 1rem;
  text-align: center;
}

.content_button > a:nth-child(2) {
  margin-left: 1rem;
}

@media (max-width: 47.9375em) and (orientation: portrait) {
  .mod_article.hero,
  .content_container {
    min-height: 90vh;
  }
}

@media screen and (min-width: 62em) {
  .content_container {
    align-items: center;
    justify-content: center;
  }
  .content_teaser {
    width: 70%;
    max-width: 650px;
    padding: 2rem;
    margin: 0;
  }
}

.ce_gallery {
  border-top: 0.25rem solid #fff;
  border-left: 0.25rem solid #fff;
}

.ce_gallery ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.ce_gallery a {
  text-decoration: none;
}

.ce_gallery li {
  float: left;
}

@media (max-width: 47.9375em) {
  .rig-columns_3 li, .rig-columns_4 li, .rig-columns_6 li, .rig-columns_8 li {
    width: 50%;
  }
}

@media (min-width: 48em) {
  .rig-columns_3 li, .rig-columns_6 li {
    width: 33.33333%;
  }
  .rig-columns_4 li, .rig-columns_8 li {
    width: 25%;
  }
}

@media (min-width: 62em) {
  .rig-columns_6 li {
    width: 16.66667%;
  }
}

@media (min-width: 75em) {
  .rig-columns_8 li {
    width: 12.5%;
  }
}

.rig-img {
  display: block;
  width: 100%;
}

.rig-cell {
  position: relative;
  border-right: 0.25rem solid #fff;
  border-bottom: 0.25rem solid #fff;
}

.rig-overlay {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  position: absolute;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  transition: .5s ease;
}

.rig-cell:hover .rig-overlay {
  opacity: 1;
}

.rig-content {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.rig-icon:after {
  font-family: "font-icon", sans-serif;
  content: "";
  font-size: 2rem;
  color: #fff;
}

.rig-icon-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  -webkit-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.rig-text {
  font-size: 1rem;
  color: #fff;
}

.lg-toolbar, .lg-thumb-outer {
  background-color: #343a40;
}

.lg-toolbar .lg-icon {
  color: #fff;
  width: 40px;
}

@media (min-width: 48em) {
  .lg-toolbar .lg-icon {
    width: 50px;
  }
}

.lg-toolbar .lg-icon:hover {
  color: #00a2e8;
}

#lg-counter {
  color: #fff;
}

.lg-actions .lg-next, .lg-actions .lg-prev {
  background-color: transparent;
  color: #fff;
  font-size: 42px;
  margin-top: -40px;
  padding: 18px 10px 19px;
  border-radius: 4px;
}

.lg-actions .lg-next:hover, .lg-actions .lg-prev:hover {
  color: #00a2e8;
}

.lg-progress-bar {
  background-color: #fff;
}

.lg-progress-bar .lg-progress {
  background-color: #00a2e8;
}

@media screen and (min-width: 80.063em) {
  .lg-backdrop {
    /* Full height */
    width: 105%;
    height: auto;
    margin: -20px;
    /* Center and scale the image nicely */
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: rgba(0, 0, 0, 0.75);
    opacity: 1;
    /* Add the blur effect */
    filter: blur(15px);
  }
}

.lg-outer {
  background-color: #495057;
}

@media screen and (min-width: 80.063em) {
  .lg-outer {
    background-color: rgba(0, 0, 0, 0.75);
  }
}

.lg-outer .lg-thumb-item {
  border: 2px solid #6c757d;
  border-radius: 4px;
}

.lg-outer .lg-thumb-item.active, .lg-outer .lg-thumb-item:hover {
  border-color: #00a2e8;
}

.lg-outer .lg-toogle-thumb {
  color: #fff;
  background-color: #343a40;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.lg-outer .lg-toogle-thumb:hover {
  color: #00a2e8;
}

.lg-sub-html {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
}

::-webkit-input-placeholder {
  color: #6c757d;
}

:-ms-input-placeholder {
  color: #6c757d;
}

::placeholder {
  color: #6c757d;
}

.widget-captcha {
  background-color: rgba(255, 128, 128, 0.5);
  padding: 1rem;
}

.widget-text label,
.widget-textarea label,
.widget-captcha label {
  color: #666666;
  display: inline-block;
  max-width: 100%;
  margin-bottom: 0.25rem;
}

.widget-captcha label {
  display: block;
}

input.text,
textarea,
input.captcha {
  display: block;
  width: 100%;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  padding: 0.5rem;
  border: 1px solid #6c757d;
  border-radius: 4px;
  background-color: transparent;
  background-clip: padding-box;
  background-image: none;
}

textarea {
  min-height: 150px;
  resize: vertical;
}

span.captcha_text {
  display: inline-block;
}

input.captcha {
  float: right;
  width: 50px;
  text-align: center;
  margin-top: -1rem;
  background-color: #fff;
}

.widget-text,
.widget-textarea,
.widget-captcha {
  margin-bottom: 1rem;
}

span.mandatory {
  color: #041f7e;
}

p.error {
  margin-bottom: 0;
}

input.submit,
button.submit {
  font-size: 1rem;
  min-width: 200px;
  margin-top: 0.25rem;
  margin-bottom: 0;
}

button.submit {
  cursor: pointer;
}

.row--flex {
  display: flex;
  align-items: stretch;
}

.row--flex > div {
  margin-bottom: 2rem;
}

.ce_hero_card {
  color: #666666;
  background: #fff;
  border: 1px solid;
  border-color: #ced4da;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.card-body {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: left;
}

.card-title {
  margin-top: 1rem;
}

.card-links {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.card-links a.btn {
  margin-bottom: 0 !important;
}

.mod_cms_accept_tags {
  font-family: Arial, Helvetica, Sans-serif;
  font-size: 14px;
  line-height: 1.33;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background: rgba(255, 255, 255, 0.5);
  color: #666666;
}

.mod_cms_accept_tags button.submit {
  min-width: 130px;
}

.mod_cms_accept_tags button[value="accept"] {
  background-color: #041f7e;
}

.mod_cms_accept_tags button[value="reject"] {
  border-color: #041f7e;
  color: #666666;
  background-color: transparent;
}

.mod_cms_accept_tags button[value="accept"].first {
  border-color: #041f7e;
  color: #041f7e;
  background-color: transparent;
}

.mod_cms_accept_tags .formbody .widget-submit {
  text-align: right;
}

@media (max-width: 35.9375em) {
  .mod_cms_accept_tags .formbody .widget-submit {
    text-align: center;
    margin-bottom: 1rem;
  }
}

.mod_cms_accept_tags form {
  display: block;
  max-width: 800px;
  margin: 1rem;
  border: 0.5rem solid #041f7e;
  border-radius: 4px;
  background: #fff;
}

.mod_cms_accept_tags form > .formbody {
  padding: 1rem;
}

.mod_cms_accept_tags form > .tags {
  display: flex;
  flex-wrap: wrap;
}

.mod_cms_accept_tags form > .tags > div {
  flex-basis: 100%;
  padding: 0 1rem 1rem 1rem;
}

@media (min-width: 36em) {
  .mod_cms_accept_tags form > .tags > div {
    flex-basis: 0;
    flex-grow: 1;
    padding: 1rem;
  }
}

.mod_cms_accept_tags form > .tags > div .head {
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.mod_cms_accept_tags form > .tags > div .head input {
  display: none;
}

.mod_cms_accept_tags form > .tags > div .head input:checked + label {
  background: #041f7e;
}

.mod_cms_accept_tags form > .tags > div .head input:checked + label:after {
  background: White;
  border-color: #041f7e;
  left: 100%;
  transform: translateX(-100%);
}

.mod_cms_accept_tags form > .tags > div .head label {
  display: inline-block;
  flex-shrink: 0;
  position: relative;
  background: #adb5bd;
  width: 44px;
  height: 20px;
  cursor: pointer;
  margin-left: 10px;
  border-radius: 17px;
}

.mod_cms_accept_tags form > .tags > div .head label:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background: White;
  border: 3px solid #adb5bd;
  border-radius: 50%;
  transition: all .3s;
}

.mod_cms_accept_tags form > .tags > div p {
  font-size: 12px;
}

.mod_cms_cookie_bar {
  font-family: Arial, Helvetica, Sans-serif;
  font-size: 1rem;
  line-height: 1.4375;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  color: #fff;
  background: #333333;
}

.mod_cms_cookie_bar button.submit {
  min-width: 150px;
}

.mod_cms_cookie_bar button[value="accept"] {
  background-color: #041f7e;
}

.mod_cms_cookie_bar button[value="reject"] {
  border-color: #041f7e;
  color: #041f7e;
  background-color: transparent;
}

.mod_cms_cookie_bar button[value="accept"].first {
  border-color: #041f7e;
  color: #041f7e;
  background-color: transparent;
}

.mod_cms_cookie_bar form {
  display: block;
  max-width: 800px;
  margin: 0 auto;
}

.mod_cms_cookie_bar form .formbody {
  margin: 1rem;
}

.mod_cms_cookie_bar form .formbody .widget-submit {
  text-align: center;
}

@media (min-width: 48em) {
  .mod_cms_cookie_bar form .formbody .widget-submit {
    text-align: right;
  }
}

.ce_optin_fallback {
  max-width: 480px;
  color: #666666;
  background-color: #fff;
  border: 2px solid #adb5bd;
  border-radius: 4px;
  padding: 1rem;
  text-align: center;
  margin: 1rem;
}

.ce_optin_fallback a.btn {
  margin: 0.5rem 0;
}

.cms-privacy-iframe {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: auto;
}

.cms-privacy-iframe__map {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

/*
.cms-privacy-iframe__map {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  .ce_optin_fallback {
    max-width: 480px;
    color: $cms-optin-fallback-color-text;
    background-color: $cms-optin-fallback-background;
    border: $cms-optin-fallback-border-width solid $cms-optin-fallback-border-color;
    @include border-radius($base-border-radius);
    @include box-shadow($color-box-shadow);
    padding: $base-spacing-unit;
    text-align: center;
    margin: $base-spacing-unit;

    a.btn {
      margin: ($base-spacing-unit * 0.5) 0;
    }
  }
}

*/
@media screen and (min-width: 0) {
  .col-xs-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xs-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-start-xs-2 {
    margin-left: 8.33333333%;
  }
}

@media (min-width: 36em) {
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-start-sm-3 {
    margin-left: 16.66666667%;
  }
}

@media (min-width: 48em) {
  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-start-md-1 {
    margin-left: 0%;
  }
}

@media (min-width: 62em) {
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-start-lg-1 {
    margin-left: 0%;
  }
  .col-start-lg-2 {
    margin-left: 8.33333333%;
  }
  .col-start-lg-4 {
    margin-left: 25%;
  }
}

@media (min-width: 75em) {
  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 47.9375em) {
  .mod_article {
    padding-top: 1.5rem;
    padding-bottom: 1.25rem;
  }
}

@media (min-width: 48em) {
  .mod_article {
    padding-top: 2rem;
    padding-bottom: 2.5rem;
  }
}

/*
#einleitung { background-color: #FFCCFF; }
#leistungen { background-color: #CCCCFF; }
#team { background-color: #CCFFFF; }
#praxis { background-color: #FFFFCC; }
#google-maps { background-color: #FFCCCC; }
*/
.tab-adress em {
  display: inline-block;
  font-style: normal;
  width: 6.5rem;
}

@media (min-width: 48em) {
  #leistungen h3, #team h3, #praxis h3, #kontakt h3 {
    padding-top: .25rem;
  }
}

/*
h1, h2, h3 {
  font-weight: bold !important;
}

h2.onepage-heading {
  font-size: (1rem * 1.25);
  margin-bottom: 0;
}

h3.onepage-heading {
  font-size: $h1-font-size;
  margin-top: 0;
  margin-bottom: 2rem;
}

h3.onepage-subheading {
  font-size: $h2-font-size;
  margin-top: 0;
  margin-bottom: 2rem;
}
*/
[id="header"] {
  border-bottom: 1px solid #BDBDBD;
}

@media (max-width: 61.9375em) {
  .onepagebar {
    height: 56px;
    transition: all 0.2s ease-in-out;
  }
  .onepagebar-brand a {
    font-size: 1.375rem;
    transition: all 0.2s ease-in-out;
  }
  .onepagebar-brand img {
    height: 40px;
    transition: all 0.2s ease-in-out;
  }
}

@media (min-width: 62em) {
  .onepagebar {
    height: 96px;
    transition: all 0.2s ease-in-out;
  }
  .onepagebar-brand img {
    height: 64px;
    transition: all 0.2s ease-in-out;
  }
  .shrink div.onepagebar {
    height: 56px;
    transition: all 0.2s ease-in-out;
  }
  .shrink div.onepagebar .onepagebar-brand a {
    font-size: 1.375rem;
    transition: all 0.2s ease-in-out;
  }
  .shrink div.onepagebar .onepagebar-brand img {
    height: 40px;
    transition: all 0.2s ease-in-out;
  }
}

[id="start"] .description {
  font-size: 2rem;
}

[id="start"] .description em {
  font-size: 1rem;
}

[id="start"] .description p:last-child {
  margin-top: -.75rem;
  padding-bottom: .75rem;
}

[id="einleitung"] {
  padding-top: 3rem;
  padding-bottom: 1rem;
}

@media (min-width: 48em) {
  [id="einleitung"] {
    padding-top: 5rem;
  }
}

[id="leistungen"] h3 {
  padding-bottom: .5rem;
}

@media (min-width: 48em) {
  [id="leistungen"] .ce_text.ce_text_right {
    margin-left: -1.5rem;
  }
}

@media (max-width: 47.9375em) {
  [id="team"] .cards-hero > div:nth-child(1) {
    margin-bottom: 3rem;
    text-align: center;
  }
  [id="team"] .ce_hero_card {
    max-width: 360px;
    margin: 0 auto;
  }
}

[id="team"] .ce_hero_card {
  margin-bottom: 1rem;
}

[id="team"] .cards-hero {
  padding-top: .75rem;
}

[id="team"] .card-content {
  text-align: center;
}

[id="praxis"] .ce_gallery {
  max-width: 1920px;
  margin: 0 auto;
  margin-top: .75rem;
}

[id="kontakt"] {
  color: #ffffff;
  background-color: #00a2e8;
  padding-top: 2rem;
  padding-bottom: 3rem;
}

@media (min-width: 48em) {
  [id="kontakt"] {
    padding-top: 3rem;
    padding-bottom: 4rem;
  }
}

[id="kontakt"] a {
  color: #041f7e;
  border-bottom: 1px dotted #041f7e;
}

[id="kontakt"] form {
  color: #666666;
  background-color: #ffffff;
  padding: 2rem;
}

[id="kontakt"] h2, [id="kontakt"] h3 {
  color: #041f7e;
}

[id="kontakt"] .kontakt-block {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

[id="kontakt"] .block-spacing {
  margin-top: 2rem;
}

@media (min-width: 62em) {
  [id="kontakt"] .kontakt-block {
    margin-top: 0;
    margin-left: 2rem;
  }
}

[id="google-maps"] {
  height: 450px;
}

[id="google-maps"].cms-privacy-iframe__map {
  background-size: cover;
}

@media (max-width: 47.9375em) {
  [id="google-maps"].cms-privacy-iframe__map {
    background-image: url("../img/splash_map_grey_small.jpg");
  }
}

@media (min-width: 48em) {
  [id="google-maps"].cms-privacy-iframe__map {
    background-image: url("../img/splash_map_grey.jpg");
  }
}

[id="footer-navbar"] {
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;
}

[id="footer-navbar"] .nav-meta a, [id="footer-navbar"] .footer-navbar-note a {
  color: #fff;
  border-bottom: 1px dotted #fff;
  padding: 0;
}

[id="footer-navbar"] .nav-meta a:hover, [id="footer-navbar"] .footer-navbar-note a:hover {
  color: #00a2e8;
  border-bottom: 1px solid #00a2e8;
}

[id="footer-navbar"] .nav-meta strong.active {
  padding: 0;
  margin-right: 1rem;
  border-bottom: 1px dotted #fff;
}

[id="footer-navbar"] .footer-navbar-metanav .nav-meta a:nth-child(1) {
  margin-right: 1rem;
}

[id="footer-navbar"] .footer-navbar-note {
  padding-top: 1rem;
}

@media (min-width: 62em) {
  [id="footer-navbar"] .container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  [id="footer-navbar"] .footer-navbar-note {
    padding-top: 0;
    flex-grow: 1;
    text-align: right;
    /* Note rechtsbuendig */
  }
}

/*
[id="footer-navbar"] {
  padding-top: $base-spacing-unit;
  padding-bottom: $base-spacing-unit
}

// .nav--meta { float: right; }
.nav-meta {
  li.first {
    margin-right: $base-spacing-unit;
  }

  a {
    color: $page-footer-navbar-text-color;
    border-bottom: 1px dotted $page-footer-navbar-text-color;
    padding: 0;

    &:hover {
      color: $color-links;
      border-bottom: 1px solid $color-links;
    }
  }
}

.footer-navbar-note {
  float: right; // float: left;
  a {
    color: $page-footer-navbar-text-color;
    border-bottom: 1px dotted $page-footer-navbar-text-color;

    &:hover {
      color: $color-links;
      border-bottom: 1px solid $color-links;
    }
  }
}

@include media-breakpoint-down-lg {
  [id="footer-navbar"] {
    text-align: center;
  }
  //  .nav--meta {
  //    float: none;
  //  }
  .footer-navbar-note {
    float: none;
    padding-top: ($base-spacing-unit / 3);
  }
}
*/
