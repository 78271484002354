// ============================================================================
// GENERIC - Contao-Reset                                  Modified: 2019-08-20
// ============================================================================


html,body,div,span,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,input,label,select,button,textarea,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{
  margin:0;padding:0;border:0;vertical-align:baseline;outline:none;}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary { display: block; }

ol,
ul {
  list-style:none;
}

a { outline:0; text-decoration:none; }

// Fix some positioning issues
.inside {
  position:relative;
}

// Float classes
.float_left {
  float:left;
}

.float_right {
  float:right;
}

// Micro clearfix, as per: css-101.org/articles/clearfix/latest-new-clearfix-so-far.php
.clear {
  height:0.1px;
  font-size:0.1px;
  line-height:0.1px;
  clear:both;
}

.clearfix,
%clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

// Hide invisible elements
.invisible,
.sidr-class-invisible {
  width:0;
  height:0;
  left:-1000px;
  top:-1000px;
  position:absolute;
  overflow:hidden;
  display:inline;
}