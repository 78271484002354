// ============================================================================
// COMPONENTS - cards                                      Modified: 2020-01-20
// ============================================================================

$card-color--text:              $color-default !default;
$card-color--background:        $color-white !default;
$card-text-align:               left !default
$card-border:                   1px solid !default;
$card-border-color:             $color-gray--400 !default;

.row--flex {
  display: flex;
  align-items: stretch;

  > div {
    margin-bottom: (2 * $base-spacing-unit);
  }
}

.ce_hero_card {
  color: $card-color--text;
  background: $card-color--background;
  border: $card-border;
  border-color: $card-border-color;
  @include border-radius($button-border-radius);
  @include box-shadow($color-box-shadow);
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.card {
  &-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: $base-spacing-unit;
    padding-right: $base-spacing-unit;
    text-align: $card-text-align;
  }

  &-title {
    margin-top: $base-spacing-unit;
  }

  &-links {
    margin-top: (2 * $base-spacing-unit);
    margin-bottom: $base-spacing-unit;

    a.btn {
      margin-bottom: 0 !important;
    }
  }
}

