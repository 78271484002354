// ============================================================================
// GENERIC - Font-Icon                                     Modified: 2020-04-14
// ============================================================================


$icon-500px: "\f26e";
$icon-address-book: "\f2b9";
$icon-address-book-o: "\f2ba";
$icon-address-card: "\f2bb";
$icon-address-card-o: "\f2bc";
$icon-adjust: "\f042";
$icon-adn: "\f170";
$icon-align-center: "\f037";
$icon-align-justify: "\f039";
$icon-align-left: "\f036";
$icon-align-right: "\f038";
$icon-amazon: "\f270";
$icon-ambulance: "\f0f9";
$icon-american-sign-language-interpreting: "\f2a3";
$icon-anchor: "\f13d";
$icon-android: "\f17b";
$icon-angellist: "\f209";
$icon-angle-double-down: "\f103";
$icon-angle-double-left: "\f100";
$icon-angle-double-right: "\f101";
$icon-angle-double-up: "\f102";
$icon-angle-down: "\f107";
$icon-angle-left: "\f104";
$icon-angle-right: "\f105";
$icon-angle-up: "\f106";
$icon-apple: "\f179";
$icon-archive: "\f187";
$icon-area-chart: "\f1fe";
$icon-arrow-circle-down: "\f0ab";
$icon-arrow-circle-left: "\f0a8";
$icon-arrow-circle-o-down: "\f01a";
$icon-arrow-circle-o-left: "\f190";
$icon-arrow-circle-o-right: "\f18e";
$icon-arrow-circle-o-up: "\f01b";
$icon-arrow-circle-right: "\f0a9";
$icon-arrow-circle-up: "\f0aa";
$icon-arrow-down: "\f063";
$icon-arrow-left: "\f060";
$icon-arrow-right: "\f061";
$icon-arrow-up: "\f062";
$icon-arrows: "\f047";
$icon-arrows-alt: "\f0b2";
$icon-arrows-h: "\f07e";
$icon-arrows-v: "\f07d";
$icon-asl-interpreting: "\f2a3";
$icon-assistive-listening-systems: "\f2a2";
$icon-asterisk: "\f069";
$icon-at: "\f1fa";
$icon-audio-description: "\f29e";
$icon-automobile: "\f1b9";
$icon-backward: "\f04a";
$icon-balance-scale: "\f24e";
$icon-ban: "\f05e";
$icon-bandcamp: "\f2d5";
$icon-bank: "\f19c";
$icon-bar-chart: "\f080";
$icon-bar-chart-o: "\f080";
$icon-barcode: "\f02a";
$icon-bars: "\f0c9";
$icon-bath: "\f2cd";
$icon-bathtub: "\f2cd";
$icon-battery: "\f240";
$icon-battery-0: "\f244";
$icon-battery-1: "\f243";
$icon-battery-2: "\f242";
$icon-battery-3: "\f241";
$icon-battery-4: "\f240";
$icon-battery-empty: "\f244";
$icon-battery-full: "\f240";
$icon-battery-half: "\f242";
$icon-battery-quarter: "\f243";
$icon-battery-three-quarters: "\f241";
$icon-bed: "\f236";
$icon-beer: "\f0fc";
$icon-behance: "\f1b4";
$icon-behance-square: "\f1b5";
$icon-bell: "\f0f3";
$icon-bell-o: "\f0a2";
$icon-bell-slash: "\f1f6";
$icon-bell-slash-o: "\f1f7";
$icon-bicycle: "\f206";
$icon-binoculars: "\f1e5";
$icon-birthday-cake: "\f1fd";
$icon-bitbucket: "\f171";
$icon-bitbucket-square: "\f172";
$icon-bitcoin: "\f15a";
$icon-black-tie: "\f27e";
$icon-blind: "\f29d";
$icon-bluetooth: "\f293";
$icon-bluetooth-b: "\f294";
$icon-bold: "\f032";
$icon-bolt: "\f0e7";
$icon-bomb: "\f1e2";
$icon-book: "\f02d";
$icon-bookmark: "\f02e";
$icon-bookmark-o: "\f097";
$icon-braille: "\f2a1";
$icon-briefcase: "\f0b1";
$icon-btc: "\f15a";
$icon-bug: "\f188";
$icon-building: "\f1ad";
$icon-building-o: "\f0f7";
$icon-bullhorn: "\f0a1";
$icon-bullseye: "\f140";
$icon-bus: "\f207";
$icon-buysellads: "\f20d";
$icon-cab: "\f1ba";
$icon-calculator: "\f1ec";
$icon-calendar: "\f073";
$icon-calendar-check-o: "\f274";
$icon-calendar-minus-o: "\f272";
$icon-calendar-o: "\f133";
$icon-calendar-plus-o: "\f271";
$icon-calendar-times-o: "\f273";
$icon-camera: "\f030";
$icon-camera-retro: "\f083";
$icon-car: "\f1b9";
$icon-caret-down: "\f0d7";
$icon-caret-left: "\f0d9";
$icon-caret-right: "\f0da";
$icon-caret-square-o-down: "\f150";
$icon-caret-square-o-left: "\f191";
$icon-caret-square-o-right: "\f152";
$icon-caret-square-o-up: "\f151";
$icon-caret-up: "\f0d8";
$icon-cart-arrow-down: "\f218";
$icon-cart-plus: "\f217";
$icon-cc: "\f20a";
$icon-cc-amex: "\f1f3";
$icon-cc-diners-club: "\f24c";
$icon-cc-discover: "\f1f2";
$icon-cc-jcb: "\f24b";
$icon-cc-mastercard: "\f1f1";
$icon-cc-paypal: "\f1f4";
$icon-cc-stripe: "\f1f5";
$icon-cc-visa: "\f1f0";
$icon-certificate: "\f0a3";
$icon-chain: "\f0c1";
$icon-chain-broken: "\f127";
$icon-check: "\f00c";
$icon-check-circle: "\f058";
$icon-check-circle-o: "\f05d";
$icon-check-square: "\f14a";
$icon-check-square-o: "\f046";
$icon-chevron-circle-down: "\f13a";
$icon-chevron-circle-left: "\f137";
$icon-chevron-circle-right: "\f138";
$icon-chevron-circle-up: "\f139";
$icon-chevron-down: "\f078";
$icon-chevron-left: "\f053";
$icon-chevron-right: "\f054";
$icon-chevron-up: "\f077";
$icon-child: "\f1ae";
$icon-chrome: "\f268";
$icon-circle: "\f111";
$icon-circle-o: "\f10c";
$icon-circle-o-notch: "\f1ce";
$icon-circle-thin: "\f1db";
$icon-clipboard: "\f0ea";
$icon-clock-o: "\f017";
$icon-clone: "\f24d";
$icon-close: "\f00d";
$icon-cloud: "\f0c2";
$icon-cloud-download: "\f0ed";
$icon-cloud-upload: "\f0ee";
$icon-cny: "\f157";
$icon-code: "\f121";
$icon-code-fork: "\f126";
$icon-codepen: "\f1cb";
$icon-codiepie: "\f284";
$icon-coffee: "\f0f4";
$icon-cog: "\f013";
$icon-cogs: "\f085";
$icon-columns: "\f0db";
$icon-comment: "\f075";
$icon-comment-o: "\f0e5";
$icon-commenting: "\f27a";
$icon-commenting-o: "\f27b";
$icon-comments: "\f086";
$icon-comments-o: "\f0e6";
$icon-compass: "\f14e";
$icon-compress: "\f066";
$icon-connectdevelop: "\f20e";
$icon-contao: "\f26d";
$icon-copy: "\f0c5";
$icon-copyright: "\f1f9";
$icon-creative-commons: "\f25e";
$icon-credit-card: "\f09d";
$icon-credit-card-alt: "\f283";
$icon-crop: "\f125";
$icon-crosshairs: "\f05b";
$icon-css3: "\f13c";
$icon-cube: "\f1b2";
$icon-cubes: "\f1b3";
$icon-cut: "\f0c4";
$icon-cutlery: "\f0f5";
$icon-dashboard: "\f0e4";
$icon-dashcube: "\f210";
$icon-database: "\f1c0";
$icon-deaf: "\f2a4";
$icon-deafness: "\f2a4";
$icon-dedent: "\f03b";
$icon-delicious: "\f1a5";
$icon-desktop: "\f108";
$icon-deviantart: "\f1bd";
$icon-diamond: "\f219";
$icon-digg: "\f1a6";
$icon-dollar: "\f155";
$icon-dot-circle-o: "\f192";
$icon-download: "\f019";
$icon-dribbble: "\f17d";
$icon-drivers-license: "\f2c2";
$icon-drivers-license-o: "\f2c3";
$icon-dropbox: "\f16b";
$icon-drupal: "\f1a9";
$icon-edge: "\f282";
$icon-edit: "\f044";
$icon-eercast: "\f2da";
$icon-eject: "\f052";
$icon-ellipsis-h: "\f141";
$icon-ellipsis-v: "\f142";
$icon-empire: "\f1d1";
$icon-envelope: "\f0e0";
$icon-envelope-o: "\f003";
$icon-envelope-open: "\f2b6";
$icon-envelope-open-o: "\f2b7";
$icon-envelope-square: "\f199";
$icon-envira: "\f299";
$icon-eraser: "\f12d";
$icon-etsy: "\f2d7";
$icon-eur: "\f153";
$icon-euro: "\f153";
$icon-exchange: "\f0ec";
$icon-exclamation: "\f12a";
$icon-exclamation-circle: "\f06a";
$icon-exclamation-triangle: "\f071";
$icon-expand: "\f065";
$icon-expeditedssl: "\f23e";
$icon-external-link: "\f08e";
$icon-external-link-square: "\f14c";
$icon-eye: "\f06e";
$icon-eye-slash: "\f070";
$icon-eyedropper: "\f1fb";
$icon-fa: "\f2b4";
$icon-facebook: "\f09a";
$icon-facebook-f: "\f09a";
$icon-facebook-official: "\f230";
$icon-facebook-square: "\f082";
$icon-fast-backward: "\f049";
$icon-fast-forward: "\f050";
$icon-fax: "\f1ac";
$icon-feed: "\f09e";
$icon-female: "\f182";
$icon-fighter-jet: "\f0fb";
$icon-file: "\f15b";
$icon-file-archive-o: "\f1c6";
$icon-file-audio-o: "\f1c7";
$icon-file-code-o: "\f1c9";
$icon-file-excel-o: "\f1c3";
$icon-file-image-o: "\f1c5";
$icon-file-movie-o: "\f1c8";
$icon-file-o: "\f016";
$icon-file-pdf-o: "\f1c1";
$icon-file-photo-o: "\f1c5";
$icon-file-picture-o: "\f1c5";
$icon-file-powerpoint-o: "\f1c4";
$icon-file-sound-o: "\f1c7";
$icon-file-text: "\f15c";
$icon-file-text-o: "\f0f6";
$icon-file-video-o: "\f1c8";
$icon-file-word-o: "\f1c2";
$icon-file-zip-o: "\f1c6";
$icon-files-o: "\f0c5";
$icon-film: "\f008";
$icon-filter: "\f0b0";
$icon-fire: "\f06d";
$icon-fire-extinguisher: "\f134";
$icon-firefox: "\f269";
$icon-first-order: "\f2b0";
$icon-flag: "\f024";
$icon-flag-checkered: "\f11e";
$icon-flag-o: "\f11d";
$icon-flash: "\f0e7";
$icon-flask: "\f0c3";
$icon-flickr: "\f16e";
$icon-floppy-o: "\f0c7";
$icon-folder: "\f07b";
$icon-folder-o: "\f114";
$icon-folder-open: "\f07c";
$icon-folder-open-o: "\f115";
$icon-font: "\f031";
$icon-font-awesome: "\f2b4";
$icon-fonticons: "\f280";
$icon-fort-awesome: "\f286";
$icon-forumbee: "\f211";
$icon-forward: "\f04e";
$icon-foursquare: "\f180";
$icon-free-code-camp: "\f2c5";
$icon-frown-o: "\f119";
$icon-futbol-o: "\f1e3";
$icon-gamepad: "\f11b";
$icon-gavel: "\f0e3";
$icon-gbp: "\f154";
$icon-ge: "\f1d1";
$icon-gear: "\f013";
$icon-gears: "\f085";
$icon-genderless: "\f22d";
$icon-get-pocket: "\f265";
$icon-gg: "\f260";
$icon-gg-circle: "\f261";
$icon-gift: "\f06b";
$icon-git: "\f1d3";
$icon-git-square: "\f1d2";
$icon-github: "\f09b";
$icon-github-alt: "\f113";
$icon-github-square: "\f092";
$icon-gitlab: "\f296";
$icon-gittip: "\f184";
$icon-glass: "\f000";
$icon-glide: "\f2a5";
$icon-glide-g: "\f2a6";
$icon-globe: "\f0ac";
$icon-google: "\f1a0";
$icon-google-plus: "\f0d5";
$icon-google-plus-circle: "\f2b3";
$icon-google-plus-official: "\f2b3";
$icon-google-plus-square: "\f0d4";
$icon-google-wallet: "\f1ee";
$icon-graduation-cap: "\f19d";
$icon-gratipay: "\f184";
$icon-grav: "\f2d6";
$icon-group: "\f0c0";
$icon-h-square: "\f0fd";
$icon-hacker-news: "\f1d4";
$icon-hand-grab-o: "\f255";
$icon-hand-lizard-o: "\f258";
$icon-hand-o-down: "\f0a7";
$icon-hand-o-left: "\f0a5";
$icon-hand-o-right: "\f0a4";
$icon-hand-o-up: "\f0a6";
$icon-hand-paper-o: "\f256";
$icon-hand-peace-o: "\f25b";
$icon-hand-pointer-o: "\f25a";
$icon-hand-rock-o: "\f255";
$icon-hand-scissors-o: "\f257";
$icon-hand-spock-o: "\f259";
$icon-hand-stop-o: "\f256";
$icon-handshake-o: "\f2b5";
$icon-hard-of-hearing: "\f2a4";
$icon-hashtag: "\f292";
$icon-hdd-o: "\f0a0";
$icon-header: "\f1dc";
$icon-headphones: "\f025";
$icon-heart: "\f004";
$icon-heart-o: "\f08a";
$icon-heartbeat: "\f21e";
$icon-history: "\f1da";
$icon-home: "\f015";
$icon-hospital-o: "\f0f8";
$icon-hotel: "\f236";
$icon-hourglass: "\f254";
$icon-hourglass-1: "\f251";
$icon-hourglass-2: "\f252";
$icon-hourglass-3: "\f253";
$icon-hourglass-end: "\f253";
$icon-hourglass-half: "\f252";
$icon-hourglass-o: "\f250";
$icon-hourglass-start: "\f251";
$icon-houzz: "\f27c";
$icon-html5: "\f13b";
$icon-i-cursor: "\f246";
$icon-id-badge: "\f2c1";
$icon-id-card: "\f2c2";
$icon-id-card-o: "\f2c3";
$icon-ils: "\f20b";
$icon-image: "\f03e";
$icon-imdb: "\f2d8";
$icon-inbox: "\f01c";
$icon-indent: "\f03c";
$icon-industry: "\f275";
$icon-info: "\f129";
$icon-info-circle: "\f05a";
$icon-inr: "\f156";
$icon-instagram: "\f16d";
$icon-institution: "\f19c";
$icon-internet-explorer: "\f26b";
$icon-intersex: "\f224";
$icon-ioxhost: "\f208";
$icon-italic: "\f033";
$icon-joomla: "\f1aa";
$icon-jpy: "\f157";
$icon-jsfiddle: "\f1cc";
$icon-key: "\f084";
$icon-keyboard-o: "\f11c";
$icon-krw: "\f159";
$icon-language: "\f1ab";
$icon-laptop: "\f109";
$icon-lastfm: "\f202";
$icon-lastfm-square: "\f203";
$icon-leaf: "\f06c";
$icon-leanpub: "\f212";
$icon-legal: "\f0e3";
$icon-lemon-o: "\f094";
$icon-level-down: "\f149";
$icon-level-up: "\f148";
$icon-life-bouy: "\f1cd";
$icon-life-buoy: "\f1cd";
$icon-life-ring: "\f1cd";
$icon-life-saver: "\f1cd";
$icon-lightbulb-o: "\f0eb";
$icon-line-chart: "\f201";
$icon-link: "\f0c1";
$icon-linkedin: "\f0e1";
$icon-linkedin-square: "\f08c";
$icon-linode: "\f2b8";
$icon-linux: "\f17c";
$icon-list: "\f03a";
$icon-list-alt: "\f022";
$icon-list-ol: "\f0cb";
$icon-list-ul: "\f0ca";
$icon-location-arrow: "\f124";
$icon-lock: "\f023";
$icon-long-arrow-down: "\f175";
$icon-long-arrow-left: "\f177";
$icon-long-arrow-right: "\f178";
$icon-long-arrow-up: "\f176";
$icon-low-vision: "\f2a8";
$icon-magic: "\f0d0";
$icon-magnet: "\f076";
$icon-mail-forward: "\f064";
$icon-mail-reply: "\f112";
$icon-mail-reply-all: "\f122";
$icon-male: "\f183";
$icon-map: "\f279";
$icon-map-marker: "\f041";
$icon-map-o: "\f278";
$icon-map-pin: "\f276";
$icon-map-signs: "\f277";
$icon-mars: "\f222";
$icon-mars-double: "\f227";
$icon-mars-stroke: "\f229";
$icon-mars-stroke-h: "\f22b";
$icon-mars-stroke-v: "\f22a";
$icon-maxcdn: "\f136";
$icon-meanpath: "\f20c";
$icon-medium: "\f23a";
$icon-medkit: "\f0fa";
$icon-meetup: "\f2e0";
$icon-meh-o: "\f11a";
$icon-mercury: "\f223";
$icon-microchip: "\f2db";
$icon-microphone: "\f130";
$icon-microphone-slash: "\f131";
$icon-minus: "\f068";
$icon-minus-circle: "\f056";
$icon-minus-square: "\f146";
$icon-minus-square-o: "\f147";
$icon-mixcloud: "\f289";
$icon-mobile: "\f10b";
$icon-mobile-phone: "\f10b";
$icon-modx: "\f285";
$icon-money: "\f0d6";
$icon-moon-o: "\f186";
$icon-mortar-board: "\f19d";
$icon-motorcycle: "\f21c";
$icon-mouse-pointer: "\f245";
$icon-music: "\f001";
$icon-navicon: "\f0c9";
$icon-neuter: "\f22c";
$icon-newspaper-o: "\f1ea";
$icon-object-group: "\f247";
$icon-object-ungroup: "\f248";
$icon-odnoklassniki: "\f263";
$icon-odnoklassniki-square: "\f264";
$icon-opencart: "\f23d";
$icon-openid: "\f19b";
$icon-opera: "\f26a";
$icon-optin-monster: "\f23c";
$icon-outdent: "\f03b";
$icon-pagelines: "\f18c";
$icon-paint-brush: "\f1fc";
$icon-paper-plane: "\f1d8";
$icon-paper-plane-o: "\f1d9";
$icon-paperclip: "\f0c6";
$icon-paragraph: "\f1dd";
$icon-paste: "\f0ea";
$icon-pause: "\f04c";
$icon-pause-circle: "\f28b";
$icon-pause-circle-o: "\f28c";
$icon-paw: "\f1b0";
$icon-paypal: "\f1ed";
$icon-pencil: "\f040";
$icon-pencil-square: "\f14b";
$icon-pencil-square-o: "\f044";
$icon-percent: "\f295";
$icon-phone: "\f095";
$icon-phone-square: "\f098";
$icon-photo: "\f03e";
$icon-picture-o: "\f03e";
$icon-pie-chart: "\f200";
$icon-pied-piper: "\f2ae";
$icon-pied-piper-alt: "\f1a8";
$icon-pied-piper-pp: "\f1a7";
$icon-pinterest: "\f0d2";
$icon-pinterest-p: "\f231";
$icon-pinterest-square: "\f0d3";
$icon-plane: "\f072";
$icon-play: "\f04b";
$icon-play-circle: "\f144";
$icon-play-circle-o: "\f01d";
$icon-plug: "\f1e6";
$icon-plus: "\f067";
$icon-plus-circle: "\f055";
$icon-plus-square: "\f0fe";
$icon-plus-square-o: "\f196";
$icon-podcast: "\f2ce";
$icon-power-off: "\f011";
$icon-print: "\f02f";
$icon-product-hunt: "\f288";
$icon-puzzle-piece: "\f12e";
$icon-qq: "\f1d6";
$icon-qrcode: "\f029";
$icon-question: "\f128";
$icon-question-circle: "\f059";
$icon-question-circle-o: "\f29c";
$icon-quora: "\f2c4";
$icon-quote-left: "\f10d";
$icon-quote-right: "\f10e";
$icon-ra: "\f1d0";
$icon-random: "\f074";
$icon-ravelry: "\f2d9";
$icon-rebel: "\f1d0";
$icon-recycle: "\f1b8";
$icon-reddit: "\f1a1";
$icon-reddit-alien: "\f281";
$icon-reddit-square: "\f1a2";
$icon-refresh: "\f021";
$icon-registered: "\f25d";
$icon-remove: "\f00d";
$icon-renren: "\f18b";
$icon-reorder: "\f0c9";
$icon-repeat: "\f01e";
$icon-reply: "\f112";
$icon-reply-all: "\f122";
$icon-resistance: "\f1d0";
$icon-retweet: "\f079";
$icon-rmb: "\f157";
$icon-road: "\f018";
$icon-rocket: "\f135";
$icon-rotate-left: "\f0e2";
$icon-rotate-right: "\f01e";
$icon-rouble: "\f158";
$icon-rss: "\f09e";
$icon-rss-square: "\f143";
$icon-rub: "\f158";
$icon-ruble: "\f158";
$icon-rupee: "\f156";
$icon-s15: "\f2cd";
$icon-safari: "\f267";
$icon-save: "\f0c7";
$icon-scissors: "\f0c4";
$icon-scribd: "\f28a";
$icon-search: "\f002";
$icon-search-minus: "\f010";
$icon-search-plus: "\f00e";
$icon-sellsy: "\f213";
$icon-send: "\f1d8";
$icon-send-o: "\f1d9";
$icon-server: "\f233";
$icon-share: "\f064";
$icon-share-alt: "\f1e0";
$icon-share-alt-square: "\f1e1";
$icon-share-square: "\f14d";
$icon-share-square-o: "\f045";
$icon-shekel: "\f20b";
$icon-sheqel: "\f20b";
$icon-shield: "\f132";
$icon-ship: "\f21a";
$icon-shirtsinbulk: "\f214";
$icon-shopping-bag: "\f290";
$icon-shopping-basket: "\f291";
$icon-shopping-cart: "\f07a";
$icon-shower: "\f2cc";
$icon-sign-in: "\f090";
$icon-sign-language: "\f2a7";
$icon-sign-out: "\f08b";
$icon-signal: "\f012";
$icon-signing: "\f2a7";
$icon-simplybuilt: "\f215";
$icon-sitemap: "\f0e8";
$icon-skyatlas: "\f216";
$icon-skype: "\f17e";
$icon-slack: "\f198";
$icon-sliders: "\f1de";
$icon-slideshare: "\f1e7";
$icon-smile-o: "\f118";
$icon-snapchat: "\f2ab";
$icon-snapchat-ghost: "\f2ac";
$icon-snapchat-square: "\f2ad";
$icon-snowflake-o: "\f2dc";
$icon-soccer-ball-o: "\f1e3";
$icon-sort: "\f0dc";
$icon-sort-alpha-asc: "\f15d";
$icon-sort-alpha-desc: "\f15e";
$icon-sort-amount-asc: "\f160";
$icon-sort-amount-desc: "\f161";
$icon-sort-asc: "\f0de";
$icon-sort-desc: "\f0dd";
$icon-sort-down: "\f0dd";
$icon-sort-numeric-asc: "\f162";
$icon-sort-numeric-desc: "\f163";
$icon-sort-up: "\f0de";
$icon-soundcloud: "\f1be";
$icon-space-shuttle: "\f197";
$icon-spinner: "\f110";
$icon-spoon: "\f1b1";
$icon-spotify: "\f1bc";
$icon-square: "\f0c8";
$icon-square-o: "\f096";
$icon-stack-exchange: "\f18d";
$icon-stack-overflow: "\f16c";
$icon-star: "\f005";
$icon-star-half: "\f089";
$icon-star-half-empty: "\f123";
$icon-star-half-full: "\f123";
$icon-star-half-o: "\f123";
$icon-star-o: "\f006";
$icon-steam: "\f1b6";
$icon-steam-square: "\f1b7";
$icon-step-backward: "\f048";
$icon-step-forward: "\f051";
$icon-stethoscope: "\f0f1";
$icon-sticky-note: "\f249";
$icon-sticky-note-o: "\f24a";
$icon-stop: "\f04d";
$icon-stop-circle: "\f28d";
$icon-stop-circle-o: "\f28e";
$icon-street-view: "\f21d";
$icon-strikethrough: "\f0cc";
$icon-stumbleupon: "\f1a4";
$icon-stumbleupon-circle: "\f1a3";
$icon-subscript: "\f12c";
$icon-subway: "\f239";
$icon-suitcase: "\f0f2";
$icon-sun-o: "\f185";
$icon-superpowers: "\f2dd";
$icon-superscript: "\f12b";
$icon-support: "\f1cd";
$icon-table: "\f0ce";
$icon-tablet: "\f10a";
$icon-tachometer: "\f0e4";
$icon-tag: "\f02b";
$icon-tags: "\f02c";
$icon-tasks: "\f0ae";
$icon-taxi: "\f1ba";
$icon-telegram: "\f2c6";
$icon-television: "\f26c";
$icon-tencent-weibo: "\f1d5";
$icon-terminal: "\f120";
$icon-text-height: "\f034";
$icon-text-width: "\f035";
$icon-th: "\f00a";
$icon-th-large: "\f009";
$icon-th-list: "\f00b";
$icon-themeisle: "\f2b2";
$icon-thermometer: "\f2c7";
$icon-thermometer-0: "\f2cb";
$icon-thermometer-1: "\f2ca";
$icon-thermometer-2: "\f2c9";
$icon-thermometer-3: "\f2c8";
$icon-thermometer-4: "\f2c7";
$icon-thermometer-empty: "\f2cb";
$icon-thermometer-full: "\f2c7";
$icon-thermometer-half: "\f2c9";
$icon-thermometer-quarter: "\f2ca";
$icon-thermometer-three-quarters: "\f2c8";
$icon-thumb-tack: "\f08d";
$icon-thumbs-down: "\f165";
$icon-thumbs-o-down: "\f088";
$icon-thumbs-o-up: "\f087";
$icon-thumbs-up: "\f164";
$icon-ticket: "\f145";
$icon-times: "\f00d";
$icon-times-circle: "\f057";
$icon-times-circle-o: "\f05c";
$icon-times-rectangle: "\f2d3";
$icon-times-rectangle-o: "\f2d4";
$icon-tint: "\f043";
$icon-toggle-down: "\f150";
$icon-toggle-left: "\f191";
$icon-toggle-off: "\f204";
$icon-toggle-on: "\f205";
$icon-toggle-right: "\f152";
$icon-toggle-up: "\f151";
$icon-trademark: "\f25c";
$icon-train: "\f238";
$icon-transgender: "\f224";
$icon-transgender-alt: "\f225";
$icon-trash: "\f1f8";
$icon-trash-o: "\f014";
$icon-tree: "\f1bb";
$icon-trello: "\f181";
$icon-tripadvisor: "\f262";
$icon-trophy: "\f091";
$icon-truck: "\f0d1";
$icon-try: "\f195";
$icon-tty: "\f1e4";
$icon-tumblr: "\f173";
$icon-tumblr-square: "\f174";
$icon-turkish-lira: "\f195";
$icon-tv: "\f26c";
$icon-twitch: "\f1e8";
$icon-twitter: "\f099";
$icon-twitter-square: "\f081";
$icon-umbrella: "\f0e9";
$icon-underline: "\f0cd";
$icon-undo: "\f0e2";
$icon-universal-access: "\f29a";
$icon-university: "\f19c";
$icon-unlink: "\f127";
$icon-unlock: "\f09c";
$icon-unlock-alt: "\f13e";
$icon-unsorted: "\f0dc";
$icon-upload: "\f093";
$icon-usb: "\f287";
$icon-usd: "\f155";
$icon-user: "\f007";
$icon-user-circle: "\f2bd";
$icon-user-circle-o: "\f2be";
$icon-user-md: "\f0f0";
$icon-user-o: "\f2c0";
$icon-user-plus: "\f234";
$icon-user-secret: "\f21b";
$icon-user-times: "\f235";
$icon-users: "\f0c0";
$icon-vcard: "\f2bb";
$icon-vcard-o: "\f2bc";
$icon-venus: "\f221";
$icon-venus-double: "\f226";
$icon-venus-mars: "\f228";
$icon-viacoin: "\f237";
$icon-viadeo: "\f2a9";
$icon-viadeo-square: "\f2aa";
$icon-video-camera: "\f03d";
$icon-vimeo: "\f27d";
$icon-vimeo-square: "\f194";
$icon-vine: "\f1ca";
$icon-vk: "\f189";
$icon-volume-control-phone: "\f2a0";
$icon-volume-down: "\f027";
$icon-volume-off: "\f026";
$icon-volume-up: "\f028";
$icon-warning: "\f071";
$icon-wechat: "\f1d7";
$icon-weibo: "\f18a";
$icon-weixin: "\f1d7";
$icon-whatsapp: "\f232";
$icon-wheelchair: "\f193";
$icon-wheelchair-alt: "\f29b";
$icon-wifi: "\f1eb";
$icon-wikipedia-w: "\f266";
$icon-window-close: "\f2d3";
$icon-window-close-o: "\f2d4";
$icon-window-maximize: "\f2d0";
$icon-window-minimize: "\f2d1";
$icon-window-restore: "\f2d2";
$icon-windows: "\f17a";
$icon-won: "\f159";
$icon-wordpress: "\f19a";
$icon-wpbeginner: "\f297";
$icon-wpexplorer: "\f2de";
$icon-wpforms: "\f298";
$icon-wrench: "\f0ad";
$icon-xing: "\f168";
$icon-xing-square: "\f169";
$icon-y-combinator: "\f23b";
$icon-y-combinator-square: "\f1d4";
$icon-yahoo: "\f19e";
$icon-yc: "\f23b";
$icon-yc-square: "\f1d4";
$icon-yelp: "\f1e9";
$icon-yen: "\f157";
$icon-yoast: "\f2b1";
$icon-youtube: "\f167";
$icon-youtube-play: "\f16a";
$icon-youtube-square: "\f166";


// Awesome Webfont
// ============================================================================

@if $font-icon-small == true {

  @font-face {
    font-family: 'font-icon';
    font-style: normal;
    font-weight: $base-weight-base;
    src: url('../fonts/font-icon-small.woff') format('woff'),
         url('../fonts/font-icon-small.woff2') format('woff2');
  }

  /* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it.
     Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
      font-family: 'font-icon';
      src: url('../fonts/font-icon-small.svg') format('svg');
    }
  }

} @else {

  @font-face {
    font-family: 'font-icon';
    font-style: normal;
    font-weight: $base-weight-base;
    src: url('../fonts/font-icon.woff') format('woff'),
         url('../fonts/font-icon.woff2') format('woff2');
  }

  /* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it.
     Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
      font-family: 'font-icon';
      src: url('../fonts/font-icon.svg') format('svg');
    }
  }

}

[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: 'font-icon' !important;
  speak: none;
  font-size: ($base-font-size / 1rem) * 1em;
  font-variant: normal;
  text-transform: none;
  line-height: $base-line-height;
  text-align: center;

  @include media-breakpoint-down-sm {
    font-size: ($base-font-size--xs / 1rem) * 1em;
    line-height: $base-line-height--xs;
  }

  @include media-breakpoint-up-hd {
    font-size: ($base-font-size--hd / 1rem) * 1em;
    line-height: $base-line-height--hd;
  }

}

.icon-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  -webkit-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

// Icons //
// .icon-500px:before { content: "\f26e"; }
// .icon-address-book:before { content: "\f2b9"; }
// .icon-address-book-o:before { content: "\f2ba"; }
// .icon-address-card:before { content: "\f2bb"; }
// .icon-address-card-o:before { content: "\f2bc"; }
// .icon-adjust:before { content: "\f042"; }
// .icon-adn:before { content: "\f170"; }
// .icon-align-center:before { content: "\f037"; }
// .icon-align-justify:before { content: "\f039"; }
// .icon-align-left:before { content: "\f036"; }
// .icon-align-right:before { content: "\f038"; }
// .icon-amazon:before { content: "\f270"; }
// .icon-ambulance:before { content: "\f0f9"; }
// .icon-american-sign-language-interpreting:before { content: "\f2a3"; }
// .icon-anchor:before { content: "\f13d"; }
// .icon-android:before { content: "\f17b"; }
// .icon-angellist:before { content: "\f209"; }
// .icon-angle-double-down:before { content: "\f103"; }
// .icon-angle-double-left:before { content: "\f100"; }
// .icon-angle-double-right:before { content: "\f101"; }
// .icon-angle-double-up:before { content: "\f102"; }
// .icon-angle-down:before { content: "\f107"; }
// .icon-angle-left:before { content: "\f104"; }
// .icon-angle-right:before { content: "\f105"; }
// .icon-angle-up:before { content: "\f106"; }
// .icon-apple:before { content: "\f179"; }
// .icon-archive:before { content: "\f187"; }
// .icon-area-chart:before { content: "\f1fe"; }
// .icon-arrow-circle-down:before { content: "\f0ab"; }
// .icon-arrow-circle-left:before { content: "\f0a8"; }
// .icon-arrow-circle-o-down:before { content: "\f01a"; }
// .icon-arrow-circle-o-left:before { content: "\f190"; }
// .icon-arrow-circle-o-right:before { content: "\f18e"; }
// .icon-arrow-circle-o-up:before { content: "\f01b"; }
// .icon-arrow-circle-right:before { content: "\f0a9"; }
// .icon-arrow-circle-up:before { content: "\f0aa"; }
// .icon-arrow-down:before { content: "\f063"; }
// .icon-arrow-left:before { content: "\f060"; }
// .icon-arrow-right:before { content: "\f061"; }
.icon-arrow-up:before { content: "\f062"; }
// .icon-arrows:before { content: "\f047"; }
// .icon-arrows-alt:before { content: "\f0b2"; }
// .icon-arrows-h:before { content: "\f07e"; }
// .icon-arrows-v:before { content: "\f07d"; }
// .icon-asl-interpreting:before { content: "\f2a3"; }
// .icon-assistive-listening-systems:before { content: "\f2a2"; }
// .icon-asterisk:before { content: "\f069"; }
// .icon-at:before { content: "\f1fa"; }
// .icon-audio-description:before { content: "\f29e"; }
// .icon-automobile:before { content: "\f1b9"; }
// .icon-backward:before { content: "\f04a"; }
// .icon-balance-scale:before { content: "\f24e"; }
// .icon-ban:before { content: "\f05e"; }
// .icon-bandcamp:before { content: "\f2d5"; }
// .icon-bank:before { content: "\f19c"; }
// .icon-bar-chart:before { content: "\f080"; }
// .icon-bar-chart-o:before { content: "\f080"; }
// .icon-barcode:before { content: "\f02a"; }
// .icon-bars:before { content: "\f0c9"; }
// .icon-bath:before { content: "\f2cd"; }
// .icon-bathtub:before { content: "\f2cd"; }
// .icon-battery:before { content: "\f240"; }
// .icon-battery-0:before { content: "\f244"; }
// .icon-battery-1:before { content: "\f243"; }
// .icon-battery-2:before { content: "\f242"; }
// .icon-battery-3:before { content: "\f241"; }
// .icon-battery-4:before { content: "\f240"; }
// .icon-battery-empty:before { content: "\f244"; }
// .icon-battery-full:before { content: "\f240"; }
// .icon-battery-half:before { content: "\f242"; }
// .icon-battery-quarter:before { content: "\f243"; }
// .icon-battery-three-quarters:before { content: "\f241"; }
// .icon-bed:before { content: "\f236"; }
// .icon-beer:before { content: "\f0fc"; }
// .icon-behance:before { content: "\f1b4"; }
// .icon-behance-square:before { content: "\f1b5"; }
// .icon-bell:before { content: "\f0f3"; }
// .icon-bell-o:before { content: "\f0a2"; }
// .icon-bell-slash:before { content: "\f1f6"; }
// .icon-bell-slash-o:before { content: "\f1f7"; }
// .icon-bicycle:before { content: "\f206"; }
// .icon-binoculars:before { content: "\f1e5"; }
// .icon-birthday-cake:before { content: "\f1fd"; }
// .icon-bitbucket:before { content: "\f171"; }
// .icon-bitbucket-square:before { content: "\f172"; }
// .icon-bitcoin:before { content: "\f15a"; }
// .icon-black-tie:before { content: "\f27e"; }
// .icon-blind:before { content: "\f29d"; }
// .icon-bluetooth:before { content: "\f293"; }
// .icon-bluetooth-b:before { content: "\f294"; }
// .icon-bold:before { content: "\f032"; }
// .icon-bolt:before { content: "\f0e7"; }
// .icon-bomb:before { content: "\f1e2"; }
// .icon-book:before { content: "\f02d"; }
// .icon-bookmark:before { content: "\f02e"; }
// .icon-bookmark-o:before { content: "\f097"; }
// .icon-braille:before { content: "\f2a1"; }
// .icon-briefcase:before { content: "\f0b1"; }
// .icon-btc:before { content: "\f15a"; }
// .icon-bug:before { content: "\f188"; }
// .icon-building:before { content: "\f1ad"; }
// .icon-building-o:before { content: "\f0f7"; }
// .icon-bullhorn:before { content: "\f0a1"; }
// .icon-bullseye:before { content: "\f140"; }
// .icon-bus:before { content: "\f207"; }
// .icon-buysellads:before { content: "\f20d"; }
// .icon-cab:before { content: "\f1ba"; }
// .icon-calculator:before { content: "\f1ec"; }
// .icon-calendar:before { content: "\f073"; }
// .icon-calendar-check-o:before { content: "\f274"; }
// .icon-calendar-minus-o:before { content: "\f272"; }
// .icon-calendar-o:before { content: "\f133"; }
// .icon-calendar-plus-o:before { content: "\f271"; }
// .icon-calendar-times-o:before { content: "\f273"; }
// .icon-camera:before { content: "\f030"; }
// .icon-camera-retro:before { content: "\f083"; }
// .icon-car:before { content: "\f1b9"; }
// .icon-caret-down:before { content: "\f0d7"; }
// .icon-caret-left:before { content: "\f0d9"; }
// .icon-caret-right:before { content: "\f0da"; }
// .icon-caret-square-o-down:before { content: "\f150"; }
// .icon-caret-square-o-left:before { content: "\f191"; }
// .icon-caret-square-o-right:before { content: "\f152"; }
// .icon-caret-square-o-up:before { content: "\f151"; }
// .icon-caret-up:before { content: "\f0d8"; }
// .icon-cart-arrow-down:before { content: "\f218"; }
// .icon-cart-plus:before { content: "\f217"; }
// .icon-cc:before { content: "\f20a"; }
// .icon-cc-amex:before { content: "\f1f3"; }
// .icon-cc-diners-club:before { content: "\f24c"; }
// .icon-cc-discover:before { content: "\f1f2"; }
// .icon-cc-jcb:before { content: "\f24b"; }
// .icon-cc-mastercard:before { content: "\f1f1"; }
// .icon-cc-paypal:before { content: "\f1f4"; }
// .icon-cc-stripe:before { content: "\f1f5"; }
// .icon-cc-visa:before { content: "\f1f0"; }
// .icon-certificate:before { content: "\f0a3"; }
// .icon-chain:before { content: "\f0c1"; }
// .icon-chain-broken:before { content: "\f127"; }
// .icon-check:before { content: "\f00c"; }
// .icon-check-circle:before { content: "\f058"; }
// .icon-check-circle-o:before { content: "\f05d"; }
// .icon-check-square:before { content: "\f14a"; }
// .icon-check-square-o:before { content: "\f046"; }
// .icon-chevron-circle-down:before { content: "\f13a"; }
// .icon-chevron-circle-left:before { content: "\f137"; }
// .icon-chevron-circle-right:before { content: "\f138"; }
// .icon-chevron-circle-up:before { content: "\f139"; }
// .icon-chevron-down:before { content: "\f078"; }
// .icon-chevron-left:before { content: "\f053"; }
// .icon-chevron-right:before { content: "\f054"; }
// .icon-chevron-up:before { content: "\f077"; }
// .icon-child:before { content: "\f1ae"; }
// .icon-chrome:before { content: "\f268"; }
// .icon-circle:before { content: "\f111"; }
// .icon-circle-o:before { content: "\f10c"; }
// .icon-circle-o-notch:before { content: "\f1ce"; }
// .icon-circle-thin:before { content: "\f1db"; }
// .icon-clipboard:before { content: "\f0ea"; }
// .icon-clock-o:before { content: "\f017"; }
// .icon-clone:before { content: "\f24d"; }
// .icon-close:before { content: "\f00d"; }
// .icon-cloud:before { content: "\f0c2"; }
// .icon-cloud-download:before { content: "\f0ed"; }
// .icon-cloud-upload:before { content: "\f0ee"; }
// .icon-cny:before { content: "\f157"; }
// .icon-code:before { content: "\f121"; }
// .icon-code-fork:before { content: "\f126"; }
// .icon-codepen:before { content: "\f1cb"; }
// .icon-codiepie:before { content: "\f284"; }
// .icon-coffee:before { content: "\f0f4"; }
// .icon-cog:before { content: "\f013"; }
// .icon-cogs:before { content: "\f085"; }
// .icon-columns:before { content: "\f0db"; }
// .icon-comment:before { content: "\f075"; }
// .icon-comment-o:before { content: "\f0e5"; }
// .icon-commenting:before { content: "\f27a"; }
// .icon-commenting-o:before { content: "\f27b"; }
// .icon-comments:before { content: "\f086"; }
// .icon-comments-o:before { content: "\f0e6"; }
// .icon-compass:before { content: "\f14e"; }
// .icon-compress:before { content: "\f066"; }
// .icon-connectdevelop:before { content: "\f20e"; }
// .icon-contao:before { content: "\f26d"; }
// .icon-copy:before { content: "\f0c5"; }
// .icon-copyright:before { content: "\f1f9"; }
// .icon-creative-commons:before { content: "\f25e"; }
// .icon-credit-card:before { content: "\f09d"; }
// .icon-credit-card-alt:before { content: "\f283"; }
// .icon-crop:before { content: "\f125"; }
// .icon-crosshairs:before { content: "\f05b"; }
// .icon-css3:before { content: "\f13c"; }
// .icon-cube:before { content: "\f1b2"; }
// .icon-cubes:before { content: "\f1b3"; }
// .icon-cut:before { content: "\f0c4"; }
// .icon-cutlery:before { content: "\f0f5"; }
// .icon-dashboard:before { content: "\f0e4"; }
// .icon-dashcube:before { content: "\f210"; }
// .icon-database:before { content: "\f1c0"; }
// .icon-deaf:before { content: "\f2a4"; }
// .icon-deafness:before { content: "\f2a4"; }
// .icon-dedent:before { content: "\f03b"; }
// .icon-delicious:before { content: "\f1a5"; }
// .icon-desktop:before { content: "\f108"; }
// .icon-deviantart:before { content: "\f1bd"; }
// .icon-diamond:before { content: "\f219"; }
// .icon-digg:before { content: "\f1a6"; }
// .icon-dollar:before { content: "\f155"; }
// .icon-dot-circle-o:before { content: "\f192"; }
// .icon-download:before { content: "\f019"; }
// .icon-dribbble:before { content: "\f17d"; }
// .icon-drivers-license:before { content: "\f2c2"; }
// .icon-drivers-license-o:before { content: "\f2c3"; }
// .icon-dropbox:before { content: "\f16b"; }
// .icon-drupal:before { content: "\f1a9"; }
// .icon-edge:before { content: "\f282"; }
// .icon-edit:before { content: "\f044"; }
// .icon-eercast:before { content: "\f2da"; }
// .icon-eject:before { content: "\f052"; }
// .icon-ellipsis-h:before { content: "\f141"; }
// .icon-ellipsis-v:before { content: "\f142"; }
// .icon-empire:before { content: "\f1d1"; }
// .icon-envelope:before { content: "\f0e0"; }
// .icon-envelope-o:before { content: "\f003"; }
// .icon-envelope-open:before { content: "\f2b6"; }
// .icon-envelope-open-o:before { content: "\f2b7"; }
// .icon-envelope-square:before { content: "\f199"; }
// .icon-envira:before { content: "\f299"; }
// .icon-eraser:before { content: "\f12d"; }
// .icon-etsy:before { content: "\f2d7"; }
// .icon-eur:before { content: "\f153"; }
// .icon-euro:before { content: "\f153"; }
// .icon-exchange:before { content: "\f0ec"; }
// .icon-exclamation:before { content: "\f12a"; }
// .icon-exclamation-circle:before { content: "\f06a"; }
// .icon-exclamation-triangle:before { content: "\f071"; }
// .icon-expand:before { content: "\f065"; }
// .icon-expeditedssl:before { content: "\f23e"; }
// .icon-external-link:before { content: "\f08e"; }
// .icon-external-link-square:before { content: "\f14c"; }
// .icon-eye:before { content: "\f06e"; }
// .icon-eye-slash:before { content: "\f070"; }
// .icon-eyedropper:before { content: "\f1fb"; }
// .icon-fa:before { content: "\f2b4"; }
// .icon-facebook:before { content: "\f09a"; }
// .icon-facebook-f:before { content: "\f09a"; }
// .icon-facebook-official:before { content: "\f230"; }
// .icon-facebook-square:before { content: "\f082"; }
// .icon-fast-backward:before { content: "\f049"; }
// .icon-fast-forward:before { content: "\f050"; }
// .icon-fax:before { content: "\f1ac"; }
// .icon-feed:before { content: "\f09e"; }
// .icon-female:before { content: "\f182"; }
// .icon-fighter-jet:before { content: "\f0fb"; }
// .icon-file:before { content: "\f15b"; }
// .icon-file-archive-o:before { content: "\f1c6"; }
// .icon-file-audio-o:before { content: "\f1c7"; }
// .icon-file-code-o:before { content: "\f1c9"; }
// .icon-file-excel-o:before { content: "\f1c3"; }
// .icon-file-image-o:before { content: "\f1c5"; }
// .icon-file-movie-o:before { content: "\f1c8"; }
// .icon-file-o:before { content: "\f016"; }
// .icon-file-pdf-o:before { content: "\f1c1"; }
// .icon-file-photo-o:before { content: "\f1c5"; }
// .icon-file-picture-o:before { content: "\f1c5"; }
// .icon-file-powerpoint-o:before { content: "\f1c4"; }
// .icon-file-sound-o:before { content: "\f1c7"; }
// .icon-file-text:before { content: "\f15c"; }
// .icon-file-text-o:before { content: "\f0f6"; }
// .icon-file-video-o:before { content: "\f1c8"; }
// .icon-file-word-o:before { content: "\f1c2"; }
// .icon-file-zip-o:before { content: "\f1c6"; }
// .icon-files-o:before { content: "\f0c5"; }
// .icon-film:before { content: "\f008"; }
// .icon-filter:before { content: "\f0b0"; }
// .icon-fire:before { content: "\f06d"; }
// .icon-fire-extinguisher:before { content: "\f134"; }
// .icon-firefox:before { content: "\f269"; }
// .icon-first-order:before { content: "\f2b0"; }
// .icon-flag:before { content: "\f024"; }
// .icon-flag-checkered:before { content: "\f11e"; }
// .icon-flag-o:before { content: "\f11d"; }
// .icon-flash:before { content: "\f0e7"; }
// .icon-flask:before { content: "\f0c3"; }
// .icon-flickr:before { content: "\f16e"; }
// .icon-floppy-o:before { content: "\f0c7"; }
// .icon-folder:before { content: "\f07b"; }
// .icon-folder-o:before { content: "\f114"; }
// .icon-folder-open:before { content: "\f07c"; }
// .icon-folder-open-o:before { content: "\f115"; }
// .icon-font:before { content: "\f031"; }
// .icon-font-awesome:before { content: "\f2b4"; }
// .icon-fonticons:before { content: "\f280"; }
// .icon-fort-awesome:before { content: "\f286"; }
// .icon-forumbee:before { content: "\f211"; }
// .icon-forward:before { content: "\f04e"; }
// .icon-foursquare:before { content: "\f180"; }
// .icon-free-code-camp:before { content: "\f2c5"; }
// .icon-frown-o:before { content: "\f119"; }
// .icon-futbol-o:before { content: "\f1e3"; }
// .icon-gamepad:before { content: "\f11b"; }
// .icon-gavel:before { content: "\f0e3"; }
// .icon-gbp:before { content: "\f154"; }
// .icon-ge:before { content: "\f1d1"; }
// .icon-gear:before { content: "\f013"; }
// .icon-gears:before { content: "\f085"; }
// .icon-genderless:before { content: "\f22d"; }
// .icon-get-pocket:before { content: "\f265"; }
// .icon-gg:before { content: "\f260"; }
// .icon-gg-circle:before { content: "\f261"; }
// .icon-gift:before { content: "\f06b"; }
// .icon-git:before { content: "\f1d3"; }
// .icon-git-square:before { content: "\f1d2"; }
// .icon-github:before { content: "\f09b"; }
// .icon-github-alt:before { content: "\f113"; }
// .icon-github-square:before { content: "\f092"; }
// .icon-gitlab:before { content: "\f296"; }
// .icon-gittip:before { content: "\f184"; }
// .icon-glass:before { content: "\f000"; }
// .icon-glide:before { content: "\f2a5"; }
// .icon-glide-g:before { content: "\f2a6"; }
// .icon-globe:before { content: "\f0ac"; }
// .icon-google:before { content: "\f1a0"; }
// .icon-google-plus:before { content: "\f0d5"; }
// .icon-google-plus-circle:before { content: "\f2b3"; }
// .icon-google-plus-official:before { content: "\f2b3"; }
// .icon-google-plus-square:before { content: "\f0d4"; }
// .icon-google-wallet:before { content: "\f1ee"; }
// .icon-graduation-cap:before { content: "\f19d"; }
// .icon-gratipay:before { content: "\f184"; }
// .icon-grav:before { content: "\f2d6"; }
// .icon-group:before { content: "\f0c0"; }
// .icon-h-square:before { content: "\f0fd"; }
// .icon-hacker-news:before { content: "\f1d4"; }
// .icon-hand-grab-o:before { content: "\f255"; }
// .icon-hand-lizard-o:before { content: "\f258"; }
// .icon-hand-o-down:before { content: "\f0a7"; }
// .icon-hand-o-left:before { content: "\f0a5"; }
// .icon-hand-o-right:before { content: "\f0a4"; }
// .icon-hand-o-up:before { content: "\f0a6"; }
// .icon-hand-paper-o:before { content: "\f256"; }
// .icon-hand-peace-o:before { content: "\f25b"; }
// .icon-hand-pointer-o:before { content: "\f25a"; }
// .icon-hand-rock-o:before { content: "\f255"; }
// .icon-hand-scissors-o:before { content: "\f257"; }
// .icon-hand-spock-o:before { content: "\f259"; }
// .icon-hand-stop-o:before { content: "\f256"; }
// .icon-handshake-o:before { content: "\f2b5"; }
// .icon-hard-of-hearing:before { content: "\f2a4"; }
// .icon-hashtag:before { content: "\f292"; }
// .icon-hdd-o:before { content: "\f0a0"; }
// .icon-header:before { content: "\f1dc"; }
// .icon-headphones:before { content: "\f025"; }
// .icon-heart:before { content: "\f004"; }
// .icon-heart-o:before { content: "\f08a"; }
// .icon-heartbeat:before { content: "\f21e"; }
// .icon-history:before { content: "\f1da"; }
// .icon-home:before { content: "\f015"; }
// .icon-hospital-o:before { content: "\f0f8"; }
// .icon-hotel:before { content: "\f236"; }
// .icon-hourglass:before { content: "\f254"; }
// .icon-hourglass-1:before { content: "\f251"; }
// .icon-hourglass-2:before { content: "\f252"; }
// .icon-hourglass-3:before { content: "\f253"; }
// .icon-hourglass-end:before { content: "\f253"; }
// .icon-hourglass-half:before { content: "\f252"; }
// .icon-hourglass-o:before { content: "\f250"; }
// .icon-hourglass-start:before { content: "\f251"; }
// .icon-houzz:before { content: "\f27c"; }
// .icon-html5:before { content: "\f13b"; }
// .icon-i-cursor:before { content: "\f246"; }
// .icon-id-badge:before { content: "\f2c1"; }
// .icon-id-card:before { content: "\f2c2"; }
// .icon-id-card-o:before { content: "\f2c3"; }
// .icon-ils:before { content: "\f20b"; }
// .icon-image:before { content: "\f03e"; }
// .icon-imdb:before { content: "\f2d8"; }
// .icon-inbox:before { content: "\f01c"; }
// .icon-indent:before { content: "\f03c"; }
// .icon-industry:before { content: "\f275"; }
// .icon-info:before { content: "\f129"; }
// .icon-info-circle:before { content: "\f05a"; }
// .icon-inr:before { content: "\f156"; }
// .icon-instagram:before { content: "\f16d"; }
// .icon-institution:before { content: "\f19c"; }
// .icon-internet-explorer:before { content: "\f26b"; }
// .icon-intersex:before { content: "\f224"; }
// .icon-ioxhost:before { content: "\f208"; }
// .icon-italic:before { content: "\f033"; }
// .icon-joomla:before { content: "\f1aa"; }
// .icon-jpy:before { content: "\f157"; }
// .icon-jsfiddle:before { content: "\f1cc"; }
// .icon-key:before { content: "\f084"; }
// .icon-keyboard-o:before { content: "\f11c"; }
// .icon-krw:before { content: "\f159"; }
// .icon-language:before { content: "\f1ab"; }
// .icon-laptop:before { content: "\f109"; }
// .icon-lastfm:before { content: "\f202"; }
// .icon-lastfm-square:before { content: "\f203"; }
// .icon-leaf:before { content: "\f06c"; }
// .icon-leanpub:before { content: "\f212"; }
// .icon-legal:before { content: "\f0e3"; }
// .icon-lemon-o:before { content: "\f094"; }
// .icon-level-down:before { content: "\f149"; }
// .icon-level-up:before { content: "\f148"; }
// .icon-life-bouy:before { content: "\f1cd"; }
// .icon-life-buoy:before { content: "\f1cd"; }
// .icon-life-ring:before { content: "\f1cd"; }
// .icon-life-saver:before { content: "\f1cd"; }
// .icon-lightbulb-o:before { content: "\f0eb"; }
// .icon-line-chart:before { content: "\f201"; }
// .icon-link:before { content: "\f0c1"; }
// .icon-linkedin:before { content: "\f0e1"; }
// .icon-linkedin-square:before { content: "\f08c"; }
// .icon-linode:before { content: "\f2b8"; }
// .icon-linux:before { content: "\f17c"; }
// .icon-list:before { content: "\f03a"; }
// .icon-list-alt:before { content: "\f022"; }
// .icon-list-ol:before { content: "\f0cb"; }
// .icon-list-ul:before { content: "\f0ca"; }
// .icon-location-arrow:before { content: "\f124"; }
// .icon-lock:before { content: "\f023"; }
// .icon-long-arrow-down:before { content: "\f175"; }
// .icon-long-arrow-left:before { content: "\f177"; }
// .icon-long-arrow-right:before { content: "\f178"; }
// .icon-long-arrow-up:before { content: "\f176"; }
// .icon-low-vision:before { content: "\f2a8"; }
// .icon-magic:before { content: "\f0d0"; }
// .icon-magnet:before { content: "\f076"; }
// .icon-mail-forward:before { content: "\f064"; }
// .icon-mail-reply:before { content: "\f112"; }
// .icon-mail-reply-all:before { content: "\f122"; }
// .icon-male:before { content: "\f183"; }
// .icon-map:before { content: "\f279"; }
// .icon-map-marker:before { content: "\f041"; }
// .icon-map-o:before { content: "\f278"; }
// .icon-map-pin:before { content: "\f276"; }
// .icon-map-signs:before { content: "\f277"; }
// .icon-mars:before { content: "\f222"; }
// .icon-mars-double:before { content: "\f227"; }
// .icon-mars-stroke:before { content: "\f229"; }
// .icon-mars-stroke-h:before { content: "\f22b"; }
// .icon-mars-stroke-v:before { content: "\f22a"; }
// .icon-maxcdn:before { content: "\f136"; }
// .icon-meanpath:before { content: "\f20c"; }
// .icon-medium:before { content: "\f23a"; }
// .icon-medkit:before { content: "\f0fa"; }
// .icon-meetup:before { content: "\f2e0"; }
// .icon-meh-o:before { content: "\f11a"; }
// .icon-mercury:before { content: "\f223"; }
// .icon-microchip:before { content: "\f2db"; }
// .icon-microphone:before { content: "\f130"; }
// .icon-microphone-slash:before { content: "\f131"; }
// .icon-minus:before { content: "\f068"; }
// .icon-minus-circle:before { content: "\f056"; }
// .icon-minus-square:before { content: "\f146"; }
// .icon-minus-square-o:before { content: "\f147"; }
// .icon-mixcloud:before { content: "\f289"; }
// .icon-mobile:before { content: "\f10b"; }
// .icon-mobile-phone:before { content: "\f10b"; }
// .icon-modx:before { content: "\f285"; }
// .icon-money:before { content: "\f0d6"; }
// .icon-moon-o:before { content: "\f186"; }
// .icon-mortar-board:before { content: "\f19d"; }
// .icon-motorcycle:before { content: "\f21c"; }
// .icon-mouse-pointer:before { content: "\f245"; }
// .icon-music:before { content: "\f001"; }
// .icon-navicon:before { content: "\f0c9"; }
// .icon-neuter:before { content: "\f22c"; }
// .icon-newspaper-o:before { content: "\f1ea"; }
// .icon-object-group:before { content: "\f247"; }
// .icon-object-ungroup:before { content: "\f248"; }
// .icon-odnoklassniki:before { content: "\f263"; }
// .icon-odnoklassniki-square:before { content: "\f264"; }
// .icon-opencart:before { content: "\f23d"; }
// .icon-openid:before { content: "\f19b"; }
// .icon-opera:before { content: "\f26a"; }
// .icon-optin-monster:before { content: "\f23c"; }
// .icon-outdent:before { content: "\f03b"; }
// .icon-pagelines:before { content: "\f18c"; }
// .icon-paint-brush:before { content: "\f1fc"; }
// .icon-paper-plane:before { content: "\f1d8"; }
// .icon-paper-plane-o:before { content: "\f1d9"; }
// .icon-paperclip:before { content: "\f0c6"; }
// .icon-paragraph:before { content: "\f1dd"; }
// .icon-paste:before { content: "\f0ea"; }
// .icon-pause:before { content: "\f04c"; }
// .icon-pause-circle:before { content: "\f28b"; }
// .icon-pause-circle-o:before { content: "\f28c"; }
// .icon-paw:before { content: "\f1b0"; }
// .icon-paypal:before { content: "\f1ed"; }
// .icon-pencil:before { content: "\f040"; }
// .icon-pencil-square:before { content: "\f14b"; }
// .icon-pencil-square-o:before { content: "\f044"; }
// .icon-percent:before { content: "\f295"; }
// .icon-phone:before { content: "\f095"; }
// .icon-phone-square:before { content: "\f098"; }
// .icon-photo:before { content: "\f03e"; }
// .icon-picture-o:before { content: "\f03e"; }
// .icon-pie-chart:before { content: "\f200"; }
// .icon-pied-piper:before { content: "\f2ae"; }
// .icon-pied-piper-alt:before { content: "\f1a8"; }
// .icon-pied-piper-pp:before { content: "\f1a7"; }
// .icon-pinterest:before { content: "\f0d2"; }
// .icon-pinterest-p:before { content: "\f231"; }
// .icon-pinterest-square:before { content: "\f0d3"; }
// .icon-plane:before { content: "\f072"; }
// .icon-play:before { content: "\f04b"; }
// .icon-play-circle:before { content: "\f144"; }
// .icon-play-circle-o:before { content: "\f01d"; }
// .icon-plug:before { content: "\f1e6"; }
// .icon-plus:before { content: "\f067"; }
// .icon-plus-circle:before { content: "\f055"; }
// .icon-plus-square:before { content: "\f0fe"; }
// .icon-plus-square-o:before { content: "\f196"; }
// .icon-podcast:before { content: "\f2ce"; }
// .icon-power-off:before { content: "\f011"; }
// .icon-print:before { content: "\f02f"; }
// .icon-product-hunt:before { content: "\f288"; }
// .icon-puzzle-piece:before { content: "\f12e"; }
// .icon-qq:before { content: "\f1d6"; }
// .icon-qrcode:before { content: "\f029"; }
// .icon-question:before { content: "\f128"; }
// .icon-question-circle:before { content: "\f059"; }
// .icon-question-circle-o:before { content: "\f29c"; }
// .icon-quora:before { content: "\f2c4"; }
// .icon-quote-left:before { content: "\f10d"; }
// .icon-quote-right:before { content: "\f10e"; }
// .icon-ra:before { content: "\f1d0"; }
// .icon-random:before { content: "\f074"; }
// .icon-ravelry:before { content: "\f2d9"; }
// .icon-rebel:before { content: "\f1d0"; }
// .icon-recycle:before { content: "\f1b8"; }
// .icon-reddit:before { content: "\f1a1"; }
// .icon-reddit-alien:before { content: "\f281"; }
// .icon-reddit-square:before { content: "\f1a2"; }
// .icon-refresh:before { content: "\f021"; }
// .icon-registered:before { content: "\f25d"; }
// .icon-remove:before { content: "\f00d"; }
// .icon-renren:before { content: "\f18b"; }
// .icon-reorder:before { content: "\f0c9"; }
// .icon-repeat:before { content: "\f01e"; }
// .icon-reply:before { content: "\f112"; }
// .icon-reply-all:before { content: "\f122"; }
// .icon-resistance:before { content: "\f1d0"; }
// .icon-retweet:before { content: "\f079"; }
// .icon-rmb:before { content: "\f157"; }
// .icon-road:before { content: "\f018"; }
// .icon-rocket:before { content: "\f135"; }
// .icon-rotate-left:before { content: "\f0e2"; }
// .icon-rotate-right:before { content: "\f01e"; }
// .icon-rouble:before { content: "\f158"; }
// .icon-rss:before { content: "\f09e"; }
// .icon-rss-square:before { content: "\f143"; }
// .icon-rub:before { content: "\f158"; }
// .icon-ruble:before { content: "\f158"; }
// .icon-rupee:before { content: "\f156"; }
// .icon-s15:before { content: "\f2cd"; }
// .icon-safari:before { content: "\f267"; }
// .icon-save:before { content: "\f0c7"; }
// .icon-scissors:before { content: "\f0c4"; }
// .icon-scribd:before { content: "\f28a"; }
// .icon-search:before { content: "\f002"; }
// .icon-search-minus:before { content: "\f010"; }
// .icon-search-plus:before { content: "\f00e"; }
// .icon-sellsy:before { content: "\f213"; }
// .icon-send:before { content: "\f1d8"; }
// .icon-send-o:before { content: "\f1d9"; }
// .icon-server:before { content: "\f233"; }
// .icon-share:before { content: "\f064"; }
// .icon-share-alt:before { content: "\f1e0"; }
// .icon-share-alt-square:before { content: "\f1e1"; }
// .icon-share-square:before { content: "\f14d"; }
// .icon-share-square-o:before { content: "\f045"; }
// .icon-shekel:before { content: "\f20b"; }
// .icon-sheqel:before { content: "\f20b"; }
// .icon-shield:before { content: "\f132"; }
// .icon-ship:before { content: "\f21a"; }
// .icon-shirtsinbulk:before { content: "\f214"; }
// .icon-shopping-bag:before { content: "\f290"; }
// .icon-shopping-basket:before { content: "\f291"; }
// .icon-shopping-cart:before { content: "\f07a"; }
// .icon-shower:before { content: "\f2cc"; }
// .icon-sign-in:before { content: "\f090"; }
// .icon-sign-language:before { content: "\f2a7"; }
// .icon-sign-out:before { content: "\f08b"; }
// .icon-signal:before { content: "\f012"; }
// .icon-signing:before { content: "\f2a7"; }
// .icon-simplybuilt:before { content: "\f215"; }
// .icon-sitemap:before { content: "\f0e8"; }
// .icon-skyatlas:before { content: "\f216"; }
// .icon-skype:before { content: "\f17e"; }
// .icon-slack:before { content: "\f198"; }
// .icon-sliders:before { content: "\f1de"; }
// .icon-slideshare:before { content: "\f1e7"; }
// .icon-smile-o:before { content: "\f118"; }
// .icon-snapchat:before { content: "\f2ab"; }
// .icon-snapchat-ghost:before { content: "\f2ac"; }
// .icon-snapchat-square:before { content: "\f2ad"; }
// .icon-snowflake-o:before { content: "\f2dc"; }
// .icon-soccer-ball-o:before { content: "\f1e3"; }
// .icon-sort:before { content: "\f0dc"; }
// .icon-sort-alpha-asc:before { content: "\f15d"; }
// .icon-sort-alpha-desc:before { content: "\f15e"; }
// .icon-sort-amount-asc:before { content: "\f160"; }
// .icon-sort-amount-desc:before { content: "\f161"; }
// .icon-sort-asc:before { content: "\f0de"; }
// .icon-sort-desc:before { content: "\f0dd"; }
// .icon-sort-down:before { content: "\f0dd"; }
// .icon-sort-numeric-asc:before { content: "\f162"; }
// .icon-sort-numeric-desc:before { content: "\f163"; }
// .icon-sort-up:before { content: "\f0de"; }
// .icon-soundcloud:before { content: "\f1be"; }
// .icon-space-shuttle:before { content: "\f197"; }
// .icon-spinner:before { content: "\f110"; }
// .icon-spoon:before { content: "\f1b1"; }
// .icon-spotify:before { content: "\f1bc"; }
// .icon-square:before { content: "\f0c8"; }
// .icon-square-o:before { content: "\f096"; }
// .icon-stack-exchange:before { content: "\f18d"; }
// .icon-stack-overflow:before { content: "\f16c"; }
// .icon-star:before { content: "\f005"; }
// .icon-star-half:before { content: "\f089"; }
// .icon-star-half-empty:before { content: "\f123"; }
// .icon-star-half-full:before { content: "\f123"; }
// .icon-star-half-o:before { content: "\f123"; }
// .icon-star-o:before { content: "\f006"; }
// .icon-steam:before { content: "\f1b6"; }
// .icon-steam-square:before { content: "\f1b7"; }
// .icon-step-backward:before { content: "\f048"; }
// .icon-step-forward:before { content: "\f051"; }
// .icon-stethoscope:before { content: "\f0f1"; }
// .icon-sticky-note:before { content: "\f249"; }
// .icon-sticky-note-o:before { content: "\f24a"; }
// .icon-stop:before { content: "\f04d"; }
// .icon-stop-circle:before { content: "\f28d"; }
// .icon-stop-circle-o:before { content: "\f28e"; }
// .icon-street-view:before { content: "\f21d"; }
// .icon-strikethrough:before { content: "\f0cc"; }
// .icon-stumbleupon:before { content: "\f1a4"; }
// .icon-stumbleupon-circle:before { content: "\f1a3"; }
// .icon-subscript:before { content: "\f12c"; }
// .icon-subway:before { content: "\f239"; }
// .icon-suitcase:before { content: "\f0f2"; }
// .icon-sun-o:before { content: "\f185"; }
// .icon-superpowers:before { content: "\f2dd"; }
// .icon-superscript:before { content: "\f12b"; }
// .icon-support:before { content: "\f1cd"; }
// .icon-table:before { content: "\f0ce"; }
// .icon-tablet:before { content: "\f10a"; }
// .icon-tachometer:before { content: "\f0e4"; }
// .icon-tag:before { content: "\f02b"; }
// .icon-tags:before { content: "\f02c"; }
// .icon-tasks:before { content: "\f0ae"; }
// .icon-taxi:before { content: "\f1ba"; }
// .icon-telegram:before { content: "\f2c6"; }
// .icon-television:before { content: "\f26c"; }
// .icon-tencent-weibo:before { content: "\f1d5"; }
// .icon-terminal:before { content: "\f120"; }
// .icon-text-height:before { content: "\f034"; }
// .icon-text-width:before { content: "\f035"; }
// .icon-th:before { content: "\f00a"; }
// .icon-th-large:before { content: "\f009"; }
// .icon-th-list:before { content: "\f00b"; }
// .icon-themeisle:before { content: "\f2b2"; }
// .icon-thermometer:before { content: "\f2c7"; }
// .icon-thermometer-0:before { content: "\f2cb"; }
// .icon-thermometer-1:before { content: "\f2ca"; }
// .icon-thermometer-2:before { content: "\f2c9"; }
// .icon-thermometer-3:before { content: "\f2c8"; }
// .icon-thermometer-4:before { content: "\f2c7"; }
// .icon-thermometer-empty:before { content: "\f2cb"; }
// .icon-thermometer-full:before { content: "\f2c7"; }
// .icon-thermometer-half:before { content: "\f2c9"; }
// .icon-thermometer-quarter:before { content: "\f2ca"; }
// .icon-thermometer-three-quarters:before { content: "\f2c8"; }
// .icon-thumb-tack:before { content: "\f08d"; }
// .icon-thumbs-down:before { content: "\f165"; }
// .icon-thumbs-o-down:before { content: "\f088"; }
// .icon-thumbs-o-up:before { content: "\f087"; }
// .icon-thumbs-up:before { content: "\f164"; }
// .icon-ticket:before { content: "\f145"; }
// .icon-times:before { content: "\f00d"; }
// .icon-times-circle:before { content: "\f057"; }
// .icon-times-circle-o:before { content: "\f05c"; }
// .icon-times-rectangle:before { content: "\f2d3"; }
// .icon-times-rectangle-o:before { content: "\f2d4"; }
// .icon-tint:before { content: "\f043"; }
// .icon-toggle-down:before { content: "\f150"; }
// .icon-toggle-left:before { content: "\f191"; }
// .icon-toggle-off:before { content: "\f204"; }
// .icon-toggle-on:before { content: "\f205"; }
// .icon-toggle-right:before { content: "\f152"; }
// .icon-toggle-up:before { content: "\f151"; }
// .icon-trademark:before { content: "\f25c"; }
// .icon-train:before { content: "\f238"; }
// .icon-transgender:before { content: "\f224"; }
// .icon-transgender-alt:before { content: "\f225"; }
// .icon-trash:before { content: "\f1f8"; }
// .icon-trash-o:before { content: "\f014"; }
// .icon-tree:before { content: "\f1bb"; }
// .icon-trello:before { content: "\f181"; }
// .icon-tripadvisor:before { content: "\f262"; }
// .icon-trophy:before { content: "\f091"; }
// .icon-truck:before { content: "\f0d1"; }
// .icon-try:before { content: "\f195"; }
// .icon-tty:before { content: "\f1e4"; }
// .icon-tumblr:before { content: "\f173"; }
// .icon-tumblr-square:before { content: "\f174"; }
// .icon-turkish-lira:before { content: "\f195"; }
// .icon-tv:before { content: "\f26c"; }
// .icon-twitch:before { content: "\f1e8"; }
// .icon-twitter:before { content: "\f099"; }
// .icon-twitter-square:before { content: "\f081"; }
// .icon-umbrella:before { content: "\f0e9"; }
// .icon-underline:before { content: "\f0cd"; }
// .icon-undo:before { content: "\f0e2"; }
// .icon-universal-access:before { content: "\f29a"; }
// .icon-university:before { content: "\f19c"; }
// .icon-unlink:before { content: "\f127"; }
// .icon-unlock:before { content: "\f09c"; }
// .icon-unlock-alt:before { content: "\f13e"; }
// .icon-unsorted:before { content: "\f0dc"; }
// .icon-upload:before { content: "\f093"; }
// .icon-usb:before { content: "\f287"; }
// .icon-usd:before { content: "\f155"; }
// .icon-user:before { content: "\f007"; }
// .icon-user-circle:before { content: "\f2bd"; }
// .icon-user-circle-o:before { content: "\f2be"; }
// .icon-user-md:before { content: "\f0f0"; }
// .icon-user-o:before { content: "\f2c0"; }
// .icon-user-plus:before { content: "\f234"; }
// .icon-user-secret:before { content: "\f21b"; }
// .icon-user-times:before { content: "\f235"; }
// .icon-users:before { content: "\f0c0"; }
// .icon-vcard:before { content: "\f2bb"; }
// .icon-vcard-o:before { content: "\f2bc"; }
// .icon-venus:before { content: "\f221"; }
// .icon-venus-double:before { content: "\f226"; }
// .icon-venus-mars:before { content: "\f228"; }
// .icon-viacoin:before { content: "\f237"; }
// .icon-viadeo:before { content: "\f2a9"; }
// .icon-viadeo-square:before { content: "\f2aa"; }
// .icon-video-camera:before { content: "\f03d"; }
// .icon-vimeo:before { content: "\f27d"; }
// .icon-vimeo-square:before { content: "\f194"; }
// .icon-vine:before { content: "\f1ca"; }
// .icon-vk:before { content: "\f189"; }
// .icon-volume-control-phone:before { content: "\f2a0"; }
// .icon-volume-down:before { content: "\f027"; }
// .icon-volume-off:before { content: "\f026"; }
// .icon-volume-up:before { content: "\f028"; }
// .icon-warning:before { content: "\f071"; }
// .icon-wechat:before { content: "\f1d7"; }
// .icon-weibo:before { content: "\f18a"; }
// .icon-weixin:before { content: "\f1d7"; }
// .icon-whatsapp:before { content: "\f232"; }
// .icon-wheelchair:before { content: "\f193"; }
// .icon-wheelchair-alt:before { content: "\f29b"; }
// .icon-wifi:before { content: "\f1eb"; }
// .icon-wikipedia-w:before { content: "\f266"; }
// .icon-window-close:before { content: "\f2d3"; }
// .icon-window-close-o:before { content: "\f2d4"; }
// .icon-window-maximize:before { content: "\f2d0"; }
// .icon-window-minimize:before { content: "\f2d1"; }
// .icon-window-restore:before { content: "\f2d2"; }
// .icon-windows:before { content: "\f17a"; }
// .icon-won:before { content: "\f159"; }
// .icon-wordpress:before { content: "\f19a"; }
// .icon-wpbeginner:before { content: "\f297"; }
// .icon-wpexplorer:before { content: "\f2de"; }
// .icon-wpforms:before { content: "\f298"; }
// .icon-wrench:before { content: "\f0ad"; }
// .icon-xing:before { content: "\f168"; }
// .icon-xing-square:before { content: "\f169"; }
// .icon-y-combinator:before { content: "\f23b"; }
// .icon-y-combinator-square:before { content: "\f1d4"; }
// .icon-yahoo:before { content: "\f19e"; }
// .icon-yc:before { content: "\f23b"; }
// .icon-yc-square:before { content: "\f1d4"; }
// .icon-yelp:before { content: "\f1e9"; }
// .icon-yen:before { content: "\f157"; }
// .icon-yoast:before { content: "\f2b1"; }
// .icon-youtube:before { content: "\f167"; }
// .icon-youtube-play:before { content: "\f16a"; }
// .icon-youtube-square:before { content: "\f166"; }