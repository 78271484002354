// ============================================================================
// COMPONENTS - Links                                      Modified: 2020-05-28
// ============================================================================
//
//  1. External Link
//  2. Content Link
//  3. Toplink
//  4. Buttons
//  5. Checkbox
//  6. Radio-Button
//  7. Switch

$toplink-color:                   $color-white !default;
$toplink-background:              $color-brand !default;
$toplink-border-width:            $base-border-width !default;
$toplink-border-color:            transparent !default;
$toplink-border-radius:           $base-border-radius !default;
$toplink-size:                    3rem !default;
$toplink-font-size:               1.75rem !default;
$toplink-icon:                    $icon-arrow-up;

$btn-color:                       $color-white !default;
$btn-background:                  $color-brand !default;
$btn-border-color:                transparent !default;
$btn-color--second:               $color-white !default;
$btn-background--second:          complement($color-brand) !default;
$btn-border-color--second:        transparent !default;
$btn-border-radius:               $base-border-radius !default;

$button-color--primary:           $btn-color !default;
$button-bg--primary:              $btn-background !default;
$button-bg-hover--primary:        darken($btn-background, 10%) !default;
$button-border--primary:          $btn-border-color !default;
$button-border-hover--primary:    darken($btn-border-color, 10%) !default;
$button-color--secondary:         $btn-color--second !default;
$button-bg--secondary:            $btn-background--second !default;
$button-bg-hover--secondary:      darken($btn-background--second, 10%) !default;
$button-border--secondary:        $btn-border-color--second !default;
$button-border-hover--secondary:  darken($btn-border-color--second, 10%) !default;
$button-spacing:                  .375rem .75rem !default;
$button-border-radius:            $btn-border-radius !default;
$button-border-width:             $base-border-width !default;
$button-font-family:              $base-font-family !default;
$button-font-weight:              $base-weight-normal !default;
$button-line-height:              $base-line-height !default;

$checkbox-color:                  $color-white !default;
$checkbox-bg:                     $color-gray--400 !default;
$checkbox-bg-hover:               darken($checkbox-bg, 10%) !default;
$checkbox-bg-checked:             $color-brand !default;
$checkbox-bg-checked-hover:       darken($checkbox-bg-checked, 10%) !default;



// Reset for mobile devices
// ============================================================================
/*
button,textarea,input, select, a {
  -webkit-tab-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tab-highlight-color: transparent;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
*/

// 1. External Link
// ============================================================================

a[href^="http"]:after {
  font-family: $base-font-icon-family;
  content: $icon-external-link;
  padding-left: 4px; }


// 2. Content Link
// ============================================================================

[id="main"],
.mod_cms_accept_tags,
.mod_cms_cookie_bar {
  a {
    color: $color-links;
    border-bottom: 1px dotted $color-links;

    &:not(.btn):hover {
      border-bottom: 1px solid $color-links;
    }
  }
}


// 3. Toplink
// ============================================================================

.toplink {
  background-color: $toplink-background;
  border: $toplink-border-width solid $toplink-border-color;
  @include border-radius($button-border-radius);
  @include box-shadow($color-box-shadow);
  display: table;
  width: $toplink-size;
  height: $toplink-size;
  bottom: 1.25rem;
  right: 1.25rem;
  position: fixed;
  z-index: 5;

  &:hover {
    background-color: darken($toplink-background, 10%);
    border-color: darken($toplink-border-color, 10%);
  }

  a {
    display: table-cell;
    vertical-align: middle;
    text-align: center;

    &::after {
      font-family: $base-font-icon-family;
      font-size: $toplink-font-size;
      line-height: 1;
      content: $toplink-icon;
      color: $toplink-color;
    }
  }

  &--left {
    left: 1.25rem;
  }

  &--circle {
    border-radius: 50%;
  }
}


// 4. Buttons
// ============================================================================

%button {
  display: inline-block;
  font-family: $button-font-family;
  font-weight: $button-font-weight;
  line-height: $button-line-height;
  color: $color-text;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  background-image: none;
  white-space: nowrap;
  padding: $button-spacing;
  border: $button-border-width solid transparent;
  @include border-radius($button-border-radius);
  @include box-shadow($color-box-shadow);
}

a.btn,
span.btn,
button.btn {
  @extend %button;
  margin-bottom: $base-spacing-unit;

  &:focus, &.focus,
  &:hover, &.hover {
    outline: 0;
  }

  &:hover {
    cursor: pointer;
  }

  &--primary {
    color: $button-color--primary;
    background-color: $button-bg--primary;
    border-color: $button-border--primary;

    &:hover {
      background-color: $button-bg-hover--primary;
      border-color: $button-border--primary;
    }
  }

  &--secondary {
    color: $button-color--secondary;
    background-color: $button-bg--secondary;
    border-color: $button-border--secondary;

    &:hover {
      background-color: $button-bg-hover--secondary;
      border-color: $button-border--secondary;
    }
  }

  &-outline--primary {
    color: $button-bg--primary;
    border-color: $button-bg--primary;

    &:hover {
      color: $button-bg-hover--primary;
      border-color: $button-bg-hover--primary;
    }
  }

  &-outline--secondary {
    color: $button-bg--secondary;
    border-color: $button-bg--secondary;

    &:hover {
      color: $button-bg-hover--secondary;
      border-color: $button-bg-hover--secondary;
    }
  }

  &-block {
    width: 100%;
  }
}


//  5. Checkbox (Template: form_checkbox)
// ============================================================================
//
//  <label class="chk-container">'label'
//    <input type="checkbox" checked="checked">
//    <span class="chk-checkmark"></span>
//  </label>

.chk-container {
  display: block;
  position: relative;
  padding-left: ($base-spacing-unit * 2);               // 35px
  margin-bottom: ($base-spacing-unit * 0.75) ;          // 12px
  cursor: pointer;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &:hover input ~ .chk-checkmark {
    background-color: $checkbox-bg-hover;
  }

  input:checked ~ .chk-checkmark {
    background-color: $checkbox-bg-checked;
  }

  &:hover input:checked ~ .chk-checkmark {
    background-color: $checkbox-bg-checked-hover;
  }
}

.chk-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: $checkbox-bg;
   @include border-radius($button-border-radius);

  &:after {
    content: "";
    position: absolute;
    display: none;
  }
}


.chk-container {
  input:checked ~ .chk-checkmark:after {
    display: block;
  }

  .chk-checkmark:after {
    left: 9px;
    top: 3px;
    width: 7px;
    height: 15px;
    border: solid $checkbox-color;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
}


//  6. Radio Button
// ============================================================================
//
//  <label class="container">One
//    <input type="radio" checked="checked" name="radio">
//    <span class="checkmark"></span>
//  </label>

/*
/* The container
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background
.container input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* When the radio button is checked, add a blue background
.container:hover input:checked ~ .checkmark {
  background-color: #33FF33;
}

/* Create the indicator (the dot/circle - hidden when not checked)
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle)
.container .checkmark:after {
 	top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
*/


//  7. Switch
// ============================================================================
//
//  <span>'label'
//    <label class="chk-toggle-switch">
//      <input type="checkbox">
//      <span class="chk-toggle-slider"></span>
//    </label>
//  </span>

.chk-switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

.chk-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $checkbox-bg;
  transition: .4s;

  &:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: $checkbox-color;
    transition: .4s;
  }
}

input {
  &:checked + .chk-slider {
    background-color: $color-brand;
  }

  &:hover:checked + .chk-slider {
    background-color: $checkbox-bg-checked-hover;
  }

  &:hover:not(:checked) + .chk-slider {
    background-color: $checkbox-bg-hover;
  }

  &:checked + .chk-slider:before {
    transform: translateX(16px);
  }
}

@if $enable-rounded {
  .chk-slider {
    border-radius: 24px;

    &:before {
      border-radius: 50%;
    }
  }
}