// ============================================================================
// Website - Styles
// ============================================================================


// Grid
// ============================================================================

// Extra small devices (portrait phones, less than 576px)
// Max container width: auto
// Class prefix: .col-xs-
// ============================================================================

@media screen and (min-width: 0) {
  .col-xs-10 { -ms-flex: 0 0 83.333333%; flex: 0 0 83.333333%; max-width: 83.333333%; }
  .col-xs-12 { -ms-flex: 0 0 100%; flex: 0 0 100%; max-width: 100%; }
  .col-start-xs-2 { margin-left:  8.33333333%; }
}


// Small devices (landscape phones, 576px and up)
// Max container width: 540px
// Class prefix: .col-sm-
// ============================================================================

@include media-breakpoint-up-sm {
  .col-sm-6 { -ms-flex: 0 0 50%; flex: 0 0 50%; max-width: 50%; }
  .col-sm-8 { -ms-flex: 0 0 66.666667%; flex: 0 0 66.666667%; max-width: 66.666667%; }
  .col-start-sm-3 { margin-left: 16.66666667%; }
}


// Medium devices (tablets, 768px and up)
// Max container width: 720px
// Class prefix: .col-md-
// ============================================================================

@include media-breakpoint-up-md {
  .col-md-2 { -ms-flex: 0 0 16.666667%; flex: 0 0 16.666667%; max-width: 16.666667%; }
  .col-md-3 { -ms-flex: 0 0 25%; flex: 0 0 25%; max-width: 25%; }
  .col-md-4 { -ms-flex: 0 0 33.333333%; flex: 0 0 33.333333%; max-width: 33.333333%; }
  .col-md-5 { -ms-flex: 0 0 41.666667%; flex: 0 0 41.666667%; max-width: 41.666667%; }
  .col-md-6 { -ms-flex: 0 0 50%; flex: 0 0 50%; max-width: 50%; }
  .col-md-7 { -ms-flex: 0 0 58.333333%; flex: 0 0 58.333333%; max-width: 58.333333%; }
  .col-md-8 { -ms-flex: 0 0 66.666667%; flex: 0 0 66.666667%; max-width: 66.666667%; }
  .col-md-9 { -ms-flex: 0 0 75%; flex: 0 0 75%; max-width: 75%; }
  .col-md-10 { -ms-flex: 0 0 83.333333%; flex: 0 0 83.333333%; max-width: 83.333333%; }
  .col-md-12 { -ms-flex: 0 0 100%; flex: 0 0 100%; max-width: 100%; }
  .col-start-md-1 { margin-left: 0%; }
}


// Large devices (desktops, 992px and up)
// Max container width: 960px
// Class prefix: .col-lg-
// ============================================================================

@include media-breakpoint-up-lg {
  .col-lg-3 { -ms-flex: 0 0 25%; flex: 0 0 25%; max-width: 25%; }
  .col-lg-4 { -ms-flex: 0 0 33.333333%; flex: 0 0 33.333333%; max-width: 33.333333%; }
  // .align-start { margin-left: 0}
  .col-start-lg-1 { margin-left: 0%; }
  .col-start-lg-2 { margin-left:  8.33333333%; }
  .col-start-lg-4 { margin-left: 25%; }
}


//
// Extra large devices (large desktops, 1200px and up)
// Max container width: 1140px
// Class prefix: .col-xl-
// ============================================================================

@include media-breakpoint-up-xl {
  .col-xl-6 { -ms-flex: 0 0 50%; flex: 0 0 50%; max-width: 50%; }
  // .col-xl-4 { -ms-flex: 0 0 33.333333%; flex: 0 0 33.333333%; max-width: 33.333333%; }
  // .col-xl-8 { -ms-flex: 0 0 66.666667%; flex: 0 0 66.666667%; max-width: 66.666667%; }
}


//
// HD devices (large desktops, 1400px and up)
// Max container width: 1140px
// ============================================================================

@include media-breakpoint-up-hd{
}

// Layout
// ============================================================================
// Mod_article
.mod_article {

  @include media-breakpoint-down-md {
    padding-top: ( 1.5 * $base-spacing-unit);
    padding-bottom: ( 1.25 * $base-spacing-unit);
  }

  @include media-breakpoint-up-md {
    padding-top: ( $base-spacing-unit--lg);
    padding-bottom: ( 1.25 * $base-spacing-unit--lg);
  }
}

/*
#einleitung { background-color: #FFCCFF; }
#leistungen { background-color: #CCCCFF; }
#team { background-color: #CCFFFF; }
#praxis { background-color: #FFFFCC; }
#google-maps { background-color: #FFCCCC; }
*/


// Typography
// ============================================================================
// Simple Tab
.tab {
  &-adress em { display: inline-block; font-style: normal; width: 6.5rem; }
}

@include media-breakpoint-up-md {
  #leistungen h3,#team h3, #praxis h3, #kontakt h3 {
    padding-top: .25rem;
  }
}

/*
h1, h2, h3 {
  font-weight: bold !important;
}

h2.onepage-heading {
  font-size: (1rem * 1.25);
  margin-bottom: 0;
}

h3.onepage-heading {
  font-size: $h1-font-size;
  margin-top: 0;
  margin-bottom: 2rem;
}

h3.onepage-subheading {
  font-size: $h2-font-size;
  margin-top: 0;
  margin-bottom: 2rem;
}
*/


// Header Navbar
// ============================================================================
[id="header"] {
  border-bottom: 1px solid #BDBDBD;
}

@include media-breakpoint-down-lg {
  .onepagebar {
    height: $onepagebar-height--small;
    transition: all 0.2s ease-in-out;
  }

  .onepagebar-brand {
    a {
      font-size: 1.375rem;
      transition: all 0.2s ease-in-out;
    }

    img {
      height: 40px;
      transition: all 0.2s ease-in-out;
    }
  }
}

@include media-breakpoint-up-lg {
  .onepagebar {
    height: $onepagebar-height;
    transition: all 0.2s ease-in-out;
  }

  .onepagebar-brand {
    img {
      height: 64px;
      transition: all 0.2s ease-in-out;
    }
  }

  .shrink div.onepagebar {
    height: $onepagebar-height--small;
    transition: all 0.2s ease-in-out;

    .onepagebar-brand {
      a {
        font-size: 1.375rem;
        transition: all 0.2s ease-in-out;
      }

      img {
        height: 40px;
        transition: all 0.2s ease-in-out;
      }
    }
  }
}


// Hero
// ============================================================================
[id="start"] {
  .description {
    font-size: 2rem;

    em {
      font-size: 1rem;
    }

    p:last-child {
      margin-top: -.75rem;
      padding-bottom: .75rem;
    }
  }
}


// Startseite
// ============================================================================
[id="einleitung"] {

  padding-top: 3rem;
  padding-bottom: 1rem;

  @include media-breakpoint-up-md {
    padding-top: 5rem;
  }
}


// Leistungen
// ============================================================================

[id="leistungen"] {

  h3 {
    padding-bottom: .5rem;
  }

  @include media-breakpoint-up-md {
    .ce_text.ce_text_right {
      margin-left: -1.5rem;
    }
  }
}


// Team
// ============================================================================
[id="team"] {

  @include media-breakpoint-down-md {
    .cards-hero > div:nth-child(1) {
      margin-bottom: 3rem;
      text-align: center;
    }

    .ce_hero_card {
      max-width: 360px;
      margin: 0 auto;
    }
  }

  .ce_hero_card {
    margin-bottom: 1rem;
    // max-width: 300px;
    // margin: 0 auto;
  }

  .cards-hero {
    padding-top: .75rem;
  }

  .card-content {
    text-align: center;
  }
}


// Praxis
// ============================================================================
[id="praxis"] {
  // padding-top: 1rem;
  // padding-bottom: 4rem;

  .ce_gallery {
    max-width: 1920px;
    margin: 0 auto;
    margin-top: .75rem;
  }
}


// Kontakt
// ============================================================================
[id="kontakt"] {
  color: $custom-color--1;
  background-color: $custom-color--6;
  padding-top: 2rem;
  padding-bottom: 3rem;
  @include media-breakpoint-up-md {
    padding-top: 3rem;
    padding-bottom: 4rem;
  }

  a {
    color: $color-links--inverted;
    border-bottom: 1px dotted $color-links--inverted;
  }

  form {
    color: $color-default;
    background-color: $custom-color--1;
    padding: 2rem;
  }

  h2, h3 {
    color: $custom-color--5;
  }

  .kontakt-block {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  .block-spacing {
    margin-top: 2rem;
  }

  @include media-breakpoint-up-lg {
    .kontakt-block {
      margin-top: 0;
      margin-left: 2rem;
    }
  }
}


// Google Maps
// ============================================================================
[id="google-maps"] {
  height: 450px;

  &.cms-privacy-iframe__map {
    @include media-breakpoint-down-md {
      background-image: url("../img/splash_map_grey_small.jpg");
    }
    @include media-breakpoint-up-md {
      background-image: url("../img/splash_map_grey.jpg");
    }
    background-size: cover;
  }
}


// Footer - Navbar
// ============================================================================
[id="footer-navbar"] {
  padding-top: $base-spacing-unit;
  padding-bottom: $base-spacing-unit;
  text-align: center;

  .nav-meta a, .footer-navbar-note a {
    color: $page-footer-navbar-text-color;
    border-bottom: 1px dotted $page-footer-navbar-text-color;
    padding: 0;

    &:hover {
      color: $color-links;
      border-bottom: 1px solid $color-links;
    }
  }

  .nav-meta strong.active {
    padding: 0;
    margin-right: $base-spacing-unit;
    border-bottom: 1px dotted $page-footer-navbar-text-color;
  }

  .footer-navbar-metanav .nav-meta a:nth-child(1) {
    margin-right: $base-spacing-unit;
  }

  .footer-navbar-note {
    padding-top: $base-spacing-unit;
  }

  @include media-breakpoint-up-lg {
    .container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }

    .footer-navbar-note {
      padding-top: 0;
      flex-grow: 1;
      text-align: right;    /* Note rechtsbuendig */
      // order: -1;            /* Note linksbuendig  */
      // text-align: left;     /* Note linksbuendig  */
    }
  }
}
/*
[id="footer-navbar"] {
  padding-top: $base-spacing-unit;
  padding-bottom: $base-spacing-unit
}

// .nav--meta { float: right; }
.nav-meta {
  li.first {
    margin-right: $base-spacing-unit;
  }

  a {
    color: $page-footer-navbar-text-color;
    border-bottom: 1px dotted $page-footer-navbar-text-color;
    padding: 0;

    &:hover {
      color: $color-links;
      border-bottom: 1px solid $color-links;
    }
  }
}

.footer-navbar-note {
  float: right; // float: left;
  a {
    color: $page-footer-navbar-text-color;
    border-bottom: 1px dotted $page-footer-navbar-text-color;

    &:hover {
      color: $color-links;
      border-bottom: 1px solid $color-links;
    }
  }
}

@include media-breakpoint-down-lg {
  [id="footer-navbar"] {
    text-align: center;
  }
  //  .nav--meta {
  //    float: none;
  //  }
  .footer-navbar-note {
    float: none;
    padding-top: ($base-spacing-unit / 3);
  }
}
*/
