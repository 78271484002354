// ============================================================================
// COMPONENTS - Grid                                       Modified: 2019-08-20
// ============================================================================


.container {
  @extend %clearfix;
  width: 100%;
  padding-right: $grid-gutter--half;
  padding-left: $grid-gutter--half;
  margin-right: auto;
  margin-left: auto;
}

@include media-breakpoint-up-sm {
  // .container { max-width: $container-max-width--sm; }
}

@include media-breakpoint-up-md{
  .container { max-width: $container-max-width--md; }
}

@include media-breakpoint-up-lg{
  .container { max-width: $container-max-width--lg; }
}

@include media-breakpoint-up-xl{
  .container { max-width: $container-max-width--xl; }
}

@include media-breakpoint-up-hd{
  .container { max-width: $container-max-width--hd; }
}

.container-fluid {
  @extend %clearfix;
  width: 100%;
  padding-right: $grid-gutter--half;
  padding-left: $grid-gutter--half;
  margin-right: auto;
  margin-left: auto;
}

.row {
  @extend %clearfix;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -$grid-gutter--half;
  margin-left: -$grid-gutter--half;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col,
.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12,
.col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12,
.col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12,
.col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12,
.col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12 {
  float: left;
  padding-left: $grid-gutter--half;
  padding-right: $grid-gutter--half;
  width: 100%;
  box-sizing: border-box;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}


// Extra small devices (portrait phones, less than 576px)
// Max container width: auto
// Class prefix: .col-xs-
// ============================================================================

@media screen and (min-width: 0) {

  // Columns
  // .col-xs-1 { -ms-flex: 0 0 8.333333%; flex: 0 0 8.333333%; max-width: 8.333333%; }
  // .col-xs-2 { -ms-flex: 0 0 16.666667%; flex: 0 0 16.666667%; max-width: 16.666667%; }
  // .col-xs-3 { -ms-flex: 0 0 25%; flex: 0 0 25%; max-width: 25%; }
  // .col-xs-4 { -ms-flex: 0 0 33.333333%; flex: 0 0 33.333333%; max-width: 33.333333%; }
  // .col-xs-5 { -ms-flex: 0 0 41.666667%; flex: 0 0 41.666667%; max-width: 41.666667%; }
  // .col-xs-6 { -ms-flex: 0 0 50%; flex: 0 0 50%; max-width: 50%; }
  // .col-xs-7 { -ms-flex: 0 0 58.333333%; flex: 0 0 58.333333%; max-width: 58.333333%; }
  // .col-xs-8 { -ms-flex: 0 0 66.666667%; flex: 0 0 66.666667%; max-width: 66.666667%; }
  // .col-xs-9 { -ms-flex: 0 0 75%; flex: 0 0 75%; max-width: 75%; }
  // .col-xs-10 { -ms-flex: 0 0 83.333333%; flex: 0 0 83.333333%; max-width: 83.333333%; }
  // .col-xs-11 { -ms-flex: 0 0 91.666667%; flex: 0 0 91.666667%; max-width: 91.666667%; }
  // .col-xs-12 { -ms-flex: 0 0 100%; flex: 0 0 100%; max-width: 100%; }

  // Start Column Position
  // .col-start-xs-1  { margin-left: 0%; }
  // .col-start-xs-2  { margin-left: 8.33333333%; }
  // .col-start-xs-3  { margin-left: 16.66666667%; }
  // .col-start-xs-4  { margin-left: 25%; }
  // .col-start-xs-5  { margin-left: 33.33333333%; }
  // .col-start-xs-6  { margin-left: 41.66666667%; }
  // .col-start-xs-7  { margin-left: 50%; }
  // .col-start-xs-8  { margin-left: 58.33333333%; }
  // .col-start-xs-9  { margin-left: 66.66666667%; }
  // .col-start-xs-10 { margin-left: 75%; }
  // .col-start-xs-11 { margin-left: 83.33333333%; }
  // .col-start-xs-12 { margin-left: 91.66666667%; }

  // Offset
  // .offset-xs-0 { margin-left: 0%; }
  // .offset-xs-1 { margin-left: 8.33333333%; }
  // .offset-xs-2 { margin-left: 16.66666667%; }
  // .offset-xs-3 { margin-left: 25%; }
  // .offset-xs-4 { margin-left: 33.33333333%; }
  // .offset-xs-5 { margin-left: 41.66666667%; }
  // .offset-xs-6 { margin-left: 50%; }
  // .offset-xs-7 { margin-left: 58.33333333%; }
  // .offset-xs-8 { margin-left: 66.66666667%; }
  // .offset-xs-9 { margin-left: 75%; }
  // .offset-xs-10 { margin-left: 83.33333333%; }
  // .offset-xs-11 { margin-left: 91.66666667%; }

  // Pull
  // .pull-right-xs { float: right; }
  // .pull-left-xs { float: left; }

  // Textalign
  // .align-left-xs { text-align: left; }
  // .align-right-xs { text-align: right; }
  // .align-center-xs { text-align: center; }
  // .align-justify-xs { text-align: justify; }

}


// Small devices (landscape phones, 576px and up)
// Max container width: 540px
// Class prefix: .col-sm-
// ============================================================================

@include media-breakpoint-up-sm {

  // Columns
  // .col-sm-1 { -ms-flex: 0 0 8.333333%; flex: 0 0 8.333333%; max-width: 8.333333%; }
  // .col-sm-2 { -ms-flex: 0 0 16.666667%; flex: 0 0 16.666667%; max-width: 16.666667%; }
  // .col-sm-3 { -ms-flex: 0 0 25%; flex: 0 0 25%; max-width: 25%; }
  // .col-sm-4 { -ms-flex: 0 0 33.333333%; flex: 0 0 33.333333%; max-width: 33.333333%; }
  // .col-sm-5 { -ms-flex: 0 0 41.666667%; flex: 0 0 41.666667%; max-width: 41.666667%; }
  // .col-sm-6 { -ms-flex: 0 0 50%; flex: 0 0 50%; max-width: 50%; }
  // .col-sm-7 { -ms-flex: 0 0 58.333333%; flex: 0 0 58.333333%; max-width: 58.333333%; }
  // .col-sm-8 { -ms-flex: 0 0 66.666667%; flex: 0 0 66.666667%; max-width: 66.666667%; }
  // .col-sm-9 { -ms-flex: 0 0 75%; flex: 0 0 75%; max-width: 75%; }
  // .col-sm-10 { -ms-flex: 0 0 83.333333%; flex: 0 0 83.333333%; max-width: 83.333333%; }
  // .col-sm-11 { -ms-flex: 0 0 91.666667%; flex: 0 0 91.666667%; max-width: 91.666667%; }
  // .col-sm-12 { -ms-flex: 0 0 100%; flex: 0 0 100%; max-width: 100%; }

  // Offset
  // .offset-sm-0 { margin-left: 0%; }
  // .offset-sm-1 { margin-left: 8.33333333%; }
  // .offset-sm-2 { margin-left: 16.66666667%; }
  // .offset-sm-3 { margin-left: 25%; }
  // .offset-sm-4 { margin-left: 33.33333333%; }
  // .offset-sm-5 { margin-left: 41.66666667%; }
  // .offset-sm-6 { margin-left: 50%; }
  // .offset-sm-7 { margin-left: 58.33333333%; }
  // .offset-sm-8 { margin-left: 66.66666667%; }
  // .offset-sm-9 { margin-left: 75%; }
  // .offset-sm-10 { margin-left: 83.33333333%; }
  // .offset-sm-11 { margin-left: 91.66666667%; }

  // Pull
  // .pull-right-sm { float: right; }
  // .pull-left-sm { float: left; }

  // Textalign
  // .align-left-sm { text-align: left; }
  // .align-right-sm { text-align: right; }
  // .align-center-sm { text-align: center; }
  // .align-justify-sm { text-align: justify; }

}


// Medium devices (tablets, 768px and up)
// Max container width: 720px
// Class prefix: .col-md-
// ============================================================================

@include media-breakpoint-up-md {

  // Columns
  // .col-md-1 { -ms-flex: 0 0 8.333333%; flex: 0 0 8.333333%; max-width: 8.333333%; }
  // .col-md-2 { -ms-flex: 0 0 16.666667%; flex: 0 0 16.666667%; max-width: 16.666667%; }
  // .col-md-3 { -ms-flex: 0 0 25%; flex: 0 0 25%; max-width: 25%; }
  // .col-md-4 { -ms-flex: 0 0 33.333333%; flex: 0 0 33.333333%; max-width: 33.333333%; }
  // .col-md-5 { -ms-flex: 0 0 41.666667%; flex: 0 0 41.666667%; max-width: 41.666667%; }
  // .col-md-6 { -ms-flex: 0 0 50%; flex: 0 0 50%; max-width: 50%; }
  // .col-md-7 { -ms-flex: 0 0 58.333333%; flex: 0 0 58.333333%; max-width: 58.333333%; }
  // .col-md-8 { -ms-flex: 0 0 66.666667%; flex: 0 0 66.666667%; max-width: 66.666667%; }
  // .col-md-9 { -ms-flex: 0 0 75%; flex: 0 0 75%; max-width: 75%; }
  // .col-md-10 { -ms-flex: 0 0 83.333333%; flex: 0 0 83.333333%; max-width: 83.333333%; }
  // .col-md-11 { -ms-flex: 0 0 91.666667%; flex: 0 0 91.666667%; max-width: 91.666667%; }
  // .col-md-12 { -ms-flex: 0 0 100%; flex: 0 0 100%; max-width: 100%; }

  // Offset
  // .offset-md-0 { margin-left: 0%; }
  // .offset-md-1 { margin-left: 8.33333333%; }
  // .offset-md-2 { margin-left: 16.66666667%; }
  // .offset-md-3 { margin-left: 25%; }
  // .offset-md-4 { margin-left: 33.33333333%; }
  // .offset-md-5 { margin-left: 41.66666667%; }
  // .offset-md-6 { margin-left: 50%; }
  // .offset-md-7 { margin-left: 58.33333333%; }
  // .offset-md-8 { margin-left: 66.66666667%; }
  // .offset-md-9 { margin-left: 75%; }
  // .offset-md-10 { margin-left: 83.33333333%; }
  // .offset-md-11 { margin-left: 91.66666667%; }

  // Pull
  // .pull-right-md { float: right; }
  // .pull-left-md { float: left; }

  // Textalign
  // .align-left-md { text-align: left; }
  // .align-right-md { text-align: right; }
  // .align-center-md { text-align: center; }
  // .align-justify-md { text-align: justify; }

}


// Large devices (desktops, 992px and up)
// Max container width: 960px
// Class prefix: .col-lg-
// ============================================================================

@include media-breakpoint-up-lg {

  // Columns
  // .col-lg-1 { -ms-flex: 0 0 8.333333%; flex: 0 0 8.333333%; max-width: 8.333333%; }
  // .col-lg-2 { -ms-flex: 0 0 16.666667%; flex: 0 0 16.666667%; max-width: 16.666667%; }
  // .col-lg-3 { -ms-flex: 0 0 25%; flex: 0 0 25%; max-width: 25%; }
  // .col-lg-4 { -ms-flex: 0 0 33.333333%; flex: 0 0 33.333333%; max-width: 33.333333%; }
  // .col-lg-5 { -ms-flex: 0 0 41.666667%; flex: 0 0 41.666667%; max-width: 41.666667%; }
  // .col-lg-6 { -ms-flex: 0 0 50%; flex: 0 0 50%; max-width: 50%; }
  // .col-lg-7 { -ms-flex: 0 0 58.333333%; flex: 0 0 58.333333%; max-width: 58.333333%; }
  // .col-lg-8 { -ms-flex: 0 0 66.666667%; flex: 0 0 66.666667%; max-width: 66.666667%; }
  // .col-lg-9 { -ms-flex: 0 0 75%; flex: 0 0 75%; max-width: 75%; }
  // .col-lg-10 { -ms-flex: 0 0 83.333333%; flex: 0 0 83.333333%; max-width: 83.333333%; }
  // .col-lg-11 { -ms-flex: 0 0 91.666667%; flex: 0 0 91.666667%; max-width: 91.666667%; }
  // .col-lg-12 { -ms-flex: 0 0 100%; flex: 0 0 100%; max-width: 100%; }

  // Offset
  // .offset-lg-0 { margin-left: 0%; }
  // .offset-lg-1 { margin-left: 8.33333333%; }
  // .offset-lg-2 { margin-left: 16.66666667%; }
  // .offset-lg-3 { margin-left: 25%; }
  // .offset-lg-4 { margin-left: 33.33333333%; }
  // .offset-lg-5 { margin-left: 41.66666667%; }
  // .offset-lg-6 { margin-left: 50%; }
  // .offset-lg-7 { margin-left: 58.33333333%; }
  // .offset-lg-8 { margin-left: 66.66666667%; }
  // .offset-lg-9 { margin-left: 75%; }
  // .offset-lg-10 { margin-left: 83.33333333%; }
  // .offset-lg-11 { margin-left: 91.66666667%; }

  // Pull
  // .pull-right-lg { float: right; }
  // .pull-left-lg { float: left; }

  // Textalign
  // .align-left-lg { text-align: left; }
  // .align-right-lg { text-align: right; }
  // .align-center-lg { text-align: center; }
  // .align-justify-lg { text-align: justify; }

}


//
// Extra large devices (large desktops, 1200px and up)
// Max container width: 1140px
// Class prefix: .col-xl-
// ============================================================================

@include media-breakpoint-up-xl {

  // Columns
  // .col-xl-1 { -ms-flex: 0 0 8.333333%; flex: 0 0 8.333333%; max-width: 8.333333%; }
  // .col-xl-2 { -ms-flex: 0 0 16.666667%; flex: 0 0 16.666667%; max-width: 16.666667%; }
  // .col-xl-3 { -ms-flex: 0 0 25%; flex: 0 0 25%; max-width: 25%; }
  // .col-xl-4 { -ms-flex: 0 0 33.333333%; flex: 0 0 33.333333%; max-width: 33.333333%; }
  // .col-xl-5 { -ms-flex: 0 0 41.666667%; flex: 0 0 41.666667%; max-width: 41.666667%; }
  // .col-xl-6 { -ms-flex: 0 0 50%; flex: 0 0 50%; max-width: 50%; }
  // .col-xl-7 { -ms-flex: 0 0 58.333333%; flex: 0 0 58.333333%; max-width: 58.333333%; }
  // .col-xl-8 { -ms-flex: 0 0 66.666667%; flex: 0 0 66.666667%; max-width: 66.666667%; }
  // .col-xl-9 { -ms-flex: 0 0 75%; flex: 0 0 75%; max-width: 75%; }
  // .col-xl-10 { -ms-flex: 0 0 83.333333%; flex: 0 0 83.333333%; max-width: 83.333333%; }
  // .col-xl-11 { -ms-flex: 0 0 91.666667%; flex: 0 0 91.666667%; max-width: 91.666667%; }
  // .col-xl-12 { -ms-flex: 0 0 100%; flex: 0 0 100%; max-width: 100%; }

  // Offset 
  // .offset-xl-0 { margin-left: 0%; }
  // .offset-xl-1 { margin-left: 8.33333333%; }
  // .offset-xl-2 { margin-left: 16.66666667%; }
  // .offset-xl-3 { margin-left: 25%; }
  // .offset-xl-4 { margin-left: 33.33333333%; }
  // .offset-xl-5 { margin-left: 41.66666667%; }
  // .offset-xl-6 { margin-left: 50%; }
  // .offset-xl-7 { margin-left: 58.33333333%; }
  // .offset-xl-8 { margin-left: 66.66666667%; }
  // .offset-xl-9 { margin-left: 75%; }
  // .offset-xl-10 { margin-left: 83.33333333%; }
  // .offset-xl-11 { margin-left: 91.66666667%; }

  // Pull 
  // .pull-right-xl { float: right; }
  // .pull-left-xl { float: left; }

  // Textalign 
  // .align-left-xl { text-align: left; }
  // .align-right-xl { text-align: right; }
  // .align-center-xl { text-align: center; }
  // .align-justify-xl { text-align: justify; }

}
