// ============================================================================
// Website Variables
// ============================================================================
//
//   1. Google Fonts
//   2. General
//   3. Navbar
//   4. Sidenav
//   5. Dropdown
//   6. Onepagenav
//   7. Buttons
//   8. Toplink
//   9. Hero
//  10. Forms


// 1. Google Fonts
// ============================================================================


// General
// ============================================================================

// Custom-Colors
$custom-color--1:                 #ffffff;  // Hintergrund, Schriftfarbe 2
$custom-color--2:                 #333333;
$custom-color--3:                 #666666;
$custom-color--4:                 #ececec;
$custom-color--5:                 #041f7e;  // Corporate Farbe 1
$custom-color--6:                 #00a2e8;  //  #2c83d4;  // Corporate Farbe 2
$custom-color--7:                 #ff0040;  // Fehler
$custom-color--8:                 #000000;
$custom-color--9:                 #000000;
$custom-color--10:                #000000;

// Colors
$color-default:                   $custom-color--3;
$color-text:                      $color-default;
$color-brand:                     $custom-color--5;
$color-brand--second:             $custom-color--6;
$color-links:                     $custom-color--6;
$color-links--inverted:           $custom-color--5;

// Page
// $color-page-text:                 $custom-color--3;

// Base Font Settings
/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'),
       url('../fonts/roboto-v20-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/roboto-v20-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'), local('Roboto-Bold'),
       url('../fonts/roboto-v20-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/roboto-v20-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


$base-font-family:                        'Roboto', sans-serif;
$base-weight-normal:                      400;
$font-icon-small:                         true;

// Base Spacing Units

// Base Border Settings

// Heading Font Setting
$h1-font-color:                           $custom-color--5;
$h2-font-color:                           $custom-color--5;
$h3-font-color:                           $custom-color--2;
$h1-font-weight:                          700;
$h2-font-weight:                          700;
$h3-font-weight:                          700;
$h4-font-weight:                          700;

// Responsive Breakpoints

// Grid columns

// Grid containers
//$container-max-width--md:                 760px;

// Options
$enable-shadows:                          false;
$enable-rounded:                          true;

// Layout
// ============================================================================
$page-header-background-color:            $custom-color--1;
$page-header-text-color:                  $custom-color--2;
$page-footer-background-color:            $custom-color--2;
$page-footer-navbar-background-color:     $custom-color--5;

// Navbar
// ============================================================================
$onepagebar-brand-text-color:             $custom-color--6;
$onepagebar-nav-color--hover:             $custom-color--6;
$onepagebar-nav-color--active:            $custom-color--6;
$onepagebar-nav-decoration-color--hover:  $custom-color--6;
$onepagebar-nav-decoration-color--active: $custom-color--6;
$onepagebar-toggler-color--hover:         $custom-color--6;
$onepagebar-overlay-background:           rgba($color-white,.9);
$onepagebar-height:                       96px;
$onepagebar-height--small:                56px;

// Hero
// ============================================================================
$hero-content-color:                      $custom-color--2;
$hero-content-background:                 rgba( $custom-color--1, .6);
$hero-content-align--horizontal:          center;

// Praxis / Gallery
// ============================================================================
$lg-icon-color--hover:                    $custom-color--6;
$lg-progressbar-background--progress:     $custom-color--6;
$lg-thumb-item-border-color--hover:       $custom-color--6;
$lg-actions-button-color--hover:          $custom-color--6;
$lg-actions-button-background:            transparent;

// CMS
// ============================================================================
$cms-tags-border-color:                   $custom-color--5;
$cms-tags-border-width:                   $base-spacing-unit--sm;

// Toplink
// ============================================================================
$toplink-border-color:                    $custom-color--1;
