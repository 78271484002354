// ============================================================================
// Variables                                               Modified: 2020-05-19
// ============================================================================
//
//  1. Colors
//  2. Page
//  3. Base Font Settings
//  4. Base Spacing Units
//  5. Base Border Settings
//  6. Heading Font Setting
//  7. Responsive Breakpoints
//  8. Grid columns
//  9. Grid containers
// 10. Options

// Colors
// ============================================================================
$color-white:                           #fff !default;
$color-gray--100:                       #f8f9fa !default;
$color-gray--200:                       #e9ecef !default;
$color-gray--300:                       #dee2e6 !default;
$color-gray--400:                       #ced4da !default;
$color-gray--500:                       #adb5bd !default;
$color-gray--600:                       #6c757d !default;
$color-gray--700:                       #495057 !default;
$color-gray--800:                       #343a40 !default;
$color-gray--900:                       #212529 !default;
$color-black:                           #000 !default;

$color-text:                            $color-gray--800 !default;
$color-brand:                           #f47c00 !default;
$color-brand--second:                   $color-brand !default;
$color-brand--third:                    $color-brand !default;
$color-highlight:                       $color-brand !default;
$color-links:                           $color-highlight !default;
$color-links--inverted:                 $color-highlight !default;

$color-box-shadow:                      0 0.25rem 0.5rem rgba($color-black, 0.5) !default;

$color-overlay:                         rgba($color-white, 0.5) !default;
$color-overlay--dark:                   rgba($color-black, 0.5) !default;

// Page
// ============================================================================
$page-min-width:                        340px !default;
$page-text-color:                       $color-text !default;
$page-text-color--inverted:             $color-white !default;
$page-background-color:                 $color-white !default;
$page-header-text-color:                $color-white !default;
$page-header-background-color:          $color-gray--800 !default;
$page-header-infobar-text-color:        $page-header-text-color !default;
$page-header-infobar-background-color:  $page-header-background-color !default;
$page-header-navbar-text-color:         $page-header-text-color !default;
$page-header-navbar-background-color:   $page-header-background-color !default;
$page-footer-text-color:                $color-white !default;
$page-footer-background-color:          $color-gray--800 !default;
$page-footer-infobar-text-color:        $page-footer-text-color !default;
$page-footer-infobar-background-color:  $page-footer-background-color !default;
$page-footer-navbar-text-color:         $page-footer-text-color !default;
$page-footer-navbar-background-color:   $page-footer-background-color !default;

// Base Font Settings
// ============================================================================
$base-font-family:                      -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$base-font-size:                        1rem !default;
$base-font-size--xs:                    ($base-font-size * .9375) !default;
$base-font-size--hd:                    ($base-font-size * 1.125) !default;

$base-font-family--second:              -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$base-font-size--second:                1rem !default;
$base-font-size--second--xs:            ($base-font-size * .9375) !default;
$base-font-size--second--hd:            ($base-font-size * 1.125) !default;

$base-font-mono-family:                 SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$base-font-mono-size:                   1rem !default;
$base-font-mono-size--xs:               ($base-font-size * .9375) !default;
$base-font-mono-size--hd:               ($base-font-size * 1.125) !default;

$base-font-icon-family:                 'font-icon', sans-serif !default;
$base-font-icon-size:                   1rem !default;
$base-font-icon-size--xs:               ($base-font-size * .9375) !default;
$base-font-icon-size--hd:               ($base-font-size * 1.125) !default;
$font-icon-small:                       false !default;

$base-weight-light:                     300 !default;
$base-weight-normal:                    400 !default;
$base-weight-bold:                      700 !default;
$base-weight-base:                      $base-weight-normal !default;

$base-line-height:                      1.5 !default;
$base-line-height--xs:                  1.4375 !default;
$base-line-height--hd:                  1.5625 !default;

// Base Spacing Units
// ============================================================================
$base-spacing-unit:                     1rem  !default;
$base-spacing-unit--xs:                 $base-spacing-unit / 4 !default;
$base-spacing-unit--sm:                 $base-spacing-unit / 2 !default;
$base-spacing-unit--lg:                 $base-spacing-unit * 2 !default;
$base-spacing-unit--hd:                 $base-spacing-unit * 4 !default;

// Base Border Settings
// ============================================================================
$base-border-radius:                    4px !default;
$base-border-width:                     2px !default;
$base-border-color:                     transparent !default;

// Heading Font Setting
// ============================================================================
$headings-margin-bottom:                ($base-spacing-unit--sm) !default;
$headings-font-family:                  $base-font-family !default;
$headings-font-weight:                  400 !default;
$headings-font-color:                   $page-text-color !default;
$headings-line-height:                  1.25 !default;

$h1-font-size:                          2.25rem !default;
$h1-font-family:                        $headings-font-family !default;
$h1-font-color:                         $headings-font-color !default;
$h1-font-weight:                        $headings-font-weight !default;

$h2-font-size:                          1.75rem !default;
$h2-font-family:                        $headings-font-family !default;
$h2-font-color:                         $headings-font-color !default;
$h2-font-weight:                        $headings-font-weight !default;

$h3-font-size:                          1.5rem !default;
$h3-font-family:                        $headings-font-family !default;
$h3-font-color:                         $headings-font-color !default;
$h3-font-weight:                        $headings-font-weight !default;

$h4-font-size:                          1rem !default;
$h4-font-family:                        $headings-font-family !default;
$h4-font-color:                         $headings-font-color !default;
$h4-font-weight:                        $headings-font-weight !default;

$h5-font-size:                          1rem !default;
$h5-font-family:                        $headings-font-family !default;
$h5-font-color:                         $headings-font-color !default;
$h5-font-weight:                        $headings-font-weight !default;

$h6-font-size:                          0.875rem !default;
$h6-font-family:                        $headings-font-family !default;
$h6-font-color:                         $headings-font-color !default;
$h6-font-weight:                        $headings-font-weight !default;

// Responsive Breakpoints
// ============================================================================
$grid-breakpoint--sm:                   36em !default;    //  576px phone-portrait
$grid-breakpoint--md:                   48em !default;    //  768px phone-landscape
$grid-breakpoint--lg:                   62em !default;    //  992px tablet
$grid-breakpoint--xl:                   75em !default;    // 1200px desktop
$grid-breakpoint--hd:                   90em !default;    // 1440px desktop large

// Grid columns
// ============================================================================
$grid-columns:                          12 !default;
$grid-gutter:                           1.875rem !default; // 30px
$grid-gutter--half:                     ($grid-gutter / 2) !default;

// Grid containers
// ============================================================================
$container-max-width--xs:               100% !default;
$container-max-width--sm:               540px !default;
$container-max-width--md:               720px !default;
$container-max-width--lg:               960px !default;
$container-max-width--xl:               1140px !default;
$container-max-width--hd:               1400px !default;

// Options
// ============================================================================
$enable-shadows:                        false !default;
$enable-rounded:                        true !default;