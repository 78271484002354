// ============================================================================
// BASE - Page                                             Modified: 2020-05-20
// ============================================================================


html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*, *:before, *:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

html {
  font-family: $base-font-family, sans-serif;
  font-size: ($base-font-size / 1rem) * 1em;
  font-weight: $base-weight-normal;
  line-height: $base-line-height;
  background-color: $page-background-color;
  color: $page-text-color;
  overflow-y: scroll;
  min-height: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  @include media-breakpoint-down-sm {
    font-size: ($base-font-size--xs / 1rem) * 1em;
    line-height: $base-line-height--xs;
  }

  @include media-breakpoint-up-hd {
    font-size: ($base-font-size--hd / 1rem) * 1em;
    line-height: $base-line-height--hd;
  }
}