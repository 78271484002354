// ============================================================================
// COMPONENTS - Navs                                       Modified: 2019-08-20
// ============================================================================
//
//  1. Vertical
//  2. Horizontal
//  3. Breadcrumb

$nav-main-item-padding:     $base-spacing-unit !default;


// General clearfix for all nav's
// ============================================================================

nav,
.nav,
.sidr-class-nav,
.onepagebar-nav {
  @extend %clearfix;
  @include navigation;
}

.nav-right {
  float: right;
}


// 1. Vertical
// ============================================================================

.nav-vertical {
  a,
  span.active,
  strong.active {
    display: block;
    font-weight: normal;
    padding: ($nav-main-item-padding / 4) 0;
  }

  span.active,
  strong.active {
    cursor: default;
  }
}


// 2. Horizontal
// ============================================================================

.nav-horizontal {
  display: inline-block;

  ul {
    @extend %clearfix;
  }

  li {
    float: left;
  }

  a,
  span.active,
  strong.active {
    font-weight: normal;
    padding: ($nav-main-item-padding / 4) $nav-main-item-padding;
  }

  span.active,
  strong.active {
    cursor: default;
  }

  a.first,
  span.first,
  strong.first {
    padding-left: 0;
  }

  a.last,
  span.last,
  strong.last {
    padding-right: 0;
  }
}


// 3. Breadcrumb
// ============================================================================
/*
.nav-breadcrumb {
  border: $base-border;
  padding: ($nav-main-item-padding / 2) $nav-main-item-padding;
  ul {
    @extend %clearfix;
  }

  li {
    @include media-query(screen-offcanvas) {
      float: left;
      padding-right: $nav-main-item-padding;
    }
  }

  a {
    &:after {
      font-size: 0.75rem;
      display: inline-block;
      content: "?";
      vertical-align: middle;
      padding-left: $$nav-main-item-padding;
    }
  }
}

*/
